<template>
  <div
    :class="{
      [styles['search-box-wrapper']]: true,
      [styles['mobile-view']]: isSearchMobileView,
      [styles['open']]: isSearchOpen,
    }"
  >
    <ZidClickOutside :class="styles['clickoutside-box']" @clickOutside="onCloseSearch">
      <div :class="styles['search-container']">
        <ZidInputGroup :class="styles['input-group']">
          <ZidInputLeading :class="styles['input-leading']" isInner>
            <ZidIcon icon="search" />
            <div v-if="selectedCategory" :class="styles['category-badge-wrapper']">
              <ZidBadge :class="styles['badge']" type="primary" inversed dismissible @dismiss="onRemoveSearchCategory">
                {{ $t(I18nMessages[selectedCategory.name]) }}
              </ZidBadge>
            </div>
          </ZidInputLeading>
          <ZidInput
            @click="openSearch"
            @change="performSearch"
            :placeholder="$t(I18nMessages['order_creation.products_and_customer.product_selection.search'])"
            :value="searchQuery"
            :disabled="disabled"
            :focus="isInputFocused"
            ref="searchInputRef"
          />
          <span v-if="!isSearchMobileView && !isSearchOpen" :class="styles['left-placeholder']">
            {{ isMac ? 'cmd + k' : 'ctrl + k' }}
          </span>
          <span v-else-if="isSearchCompleted" :class="styles['search-coutner']">
            {{ searchResultsCount }} {{ $t(I18nMessages['common.result']) }}
          </span>
          <button :class="styles['close-search']" @click="onCloseSearch" v-if="isSearchOpen">
            <ZidIcon icon="close" size="xxs" />
          </button>
        </ZidInputGroup>
        <GlobalSearchComponentsSearchDropdownComponent v-if="isSearchOpen" />
      </div>
    </ZidClickOutside>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, watch, toRefs, ref } from 'vue';
import { ZidInputGroup, ZidInputLeading, ZidIcon, ZidInput, ZidClickOutside, ZidBadge } from '@zidsa/ui';
import GlobalSearchComponentsSearchDropdownComponent from './SearchDropdown/SearchDropdown.vue';
import { globalSearchModule } from '../../../store/GlobalSearch/module';
import { useGlobalSearch } from '../../../composables/useGlobalSearch';
import useI18nMessages from '../../../../common/composables/useI18nMessages';
import { onBeforeUnmount } from 'vue';
export default defineComponent({
  components: {
    ZidInputGroup,
    ZidInputLeading,
    ZidIcon,
    ZidInput,
    ZidClickOutside,
    GlobalSearchComponentsSearchDropdownComponent,
    ZidBadge,
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    isSearchMobileView: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const { performSearch, selectedCategory, searchQuery, searchResultsCount } = useGlobalSearch();
    const { disabled, isSearchMobileView } = toRefs(props);
    const I18nMessages = useI18nMessages();
    const searchInputRef = ref<null>(null);
    const isMac = ref(false);
    const isSearchOpen = computed((): boolean => globalSearchModule.isSearchOpen);
    const isInputFocused = computed((): boolean => globalSearchModule.isInputFocused);
    const isSearchCompleted = computed((): boolean => {
      if (selectedCategory.value) return globalSearchModule.isCategorySearchCompleted;
      return globalSearchModule.isGlobalSearchCompleted;
    });

    onMounted((): void => {
      isMac.value = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
      globalSearchModule.LOAD_SEARCH_HISTORY();
      window.addEventListener('keydown', handleKeydown);
    });

    onBeforeUnmount((): void => {
      window.removeEventListener('keydown', handleKeydown);
    });

    const openSearch = (): void => {
      globalSearchModule.TOGGLE_SEARCH_VIEW(true);
      globalSearchModule.TOGGLE_INPUT_FOCUS(true);
    };

    const onCloseSearch = (event?: Event): void => {
      event?.stopPropagation();
      globalSearchModule.TOGGLE_INPUT_FOCUS(false);
      globalSearchModule.TOGGLE_SEARCH_VIEW(false);
    };

    const onRemoveSearchCategory = (): void => {
      globalSearchModule.RESET_SELECTED_SEARCH_CATEGORIES();
      globalSearchModule.RESET_SEARCH_RESULTS();
      performSearch({ value: searchQuery.value });
    };

    const handleKeydown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && (event.key === 'k' || event.key === 'ن')) {
        event.preventDefault();
        openSearch();
      } else if (event.key === 'Escape' || event.key === 'Esc') {
        event.preventDefault();
        if (isSearchOpen.value) {
          onCloseSearch();
        }
      }
    };

    const focusInput = () => {
      setTimeout(() => {
        const inputRef = searchInputRef.value as any;
        if (inputRef && inputRef.$refs.input) {
          inputRef.$refs.input.focus();
        }
      }, 300);
    };

    const removeInputFocus = () => {
      setTimeout(() => {
        const inputRef = searchInputRef.value as any;
        if (inputRef && inputRef.$refs.input) {
          inputRef.$refs.input.blur();
        }
      }, 200)
    };

    watch(isSearchOpen, (newValue) => {
      emit('toggleSearchInput', newValue);
    });

    watch(isInputFocused, (newValue) => {
      if (newValue) focusInput();
      else if (!newValue) removeInputFocus();
    });

    return {
      openSearch,
      onCloseSearch,
      isSearchOpen,
      selectedCategory,
      onRemoveSearchCategory,
      performSearch,
      searchQuery,
      disabled,
      isSearchMobileView,
      I18nMessages,
      isInputFocused,
      searchInputRef,
      isMac,
      isSearchCompleted,
      searchResultsCount,
    };
  },
});
</script>

<style lang="scss" module="styles" src="./GloablSearch.module.scss"></style>
