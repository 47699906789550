import { OrdersApiFiltersInterface } from '../filters.interface';
import { ApiSearchParamsType } from '../../../api/types';
import { objectFilter } from '../../../common/helpers/object/filter';
import { OrdersApiOrdersSearchParamsInterface } from './search-params.interface';

export function ordersApiOrdersFiltersToSearchParams(filters: OrdersApiFiltersInterface): ApiSearchParamsType {
  // Some of the filters search params use camel case that's not allowed
  // inside the application. Disable the rule in order to prepare the search params object
  /* eslint-disable @typescript-eslint/camelcase */
  const params: OrdersApiOrdersSearchParamsInterface = {
    customer_id: filters.customerId !== undefined ? String(filters.customerId) : undefined,
    coupon_id: filters.couponId !== undefined ? String(filters.couponId) : undefined,
    date_attribute: filters.dateAttribute !== undefined ? String(filters.dateAttribute) : undefined,
    date_from: filters.dateFrom,
    date_to: filters.dateTo,
    limit: filters.limit !== undefined ? String(filters.limit) : undefined,
    order_by: filters.orderBy,
    order_id: filters.orderId !== undefined ? String(filters.orderId) : undefined,
    page: filters.page !== undefined ? String(filters.page) : undefined,
    payment_method: filters.paymentMethod,
    search: filters.search,
    shipping_country: filters.shippingCountry,
    shipping_city: filters.shippingCity,
    shipping_method: filters.shippingMethod,
    shipping_method_code: filters.shippingMethodCode,
    sort_by: filters.sortBy,
    status: filters.orderStatus,
    transaction_reference: filters.transactionReference,
    tags: filters.tags?.length ? filters.tags?.map((tag) => tag.id) : undefined,
    referer_code: filters.refererCode,
    payment_status: filters.orderPaymentStatus,
    source: filters.source,
    customer_type: filters.customer_type,
    cashier: filters.cashier,
    sku: filters.sku,
    ['is_gift_order']: filters.isGiftOrder,
  };
  /* eslint-enable @typescript-eslint/camelcase */

  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
