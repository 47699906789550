import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../../../app/store/factory';
import AppStoreReadyStateEnum from '../../../../app/store/ready-state.enum';
import Catch, { getServerErrorMessage } from '../../../../common/decorators/catch-error';
import { SubscriptionCheckoutApiServiceFactory } from '../../../api/subscription-checkout/service-factory';
import { ApiModelPurchaseInterface } from '../../../../api/model/purchase/interface';
import { SubscriptionTypesSubscriptionPurchasableRequestInterface } from '../../../types/subscription-purchasable.interface';
import { SubscriptionApiPurchaseResponseInterface } from '../../../api/subscription-checkout/purchase/response.interface';
import { SubscriptionTypesPaymentMethodEnum } from '../../../types/payment-methods.enum';
import { InputTextChangeEventInterface } from '../../../../common/components/Input';
import { SubscriptionTypesSubscriptionCheckoutAddPurchaseItemRequestInterface } from '../../../types/subscription-checkout-add-purchase-item-request.interface';
import { ApiModelPurchaseInvoicePurchasedItemInterface } from '../../../../api/model/purchase/invoice.interface';
import { SupscriptionTypesConfirmCheckoutResponseInterface } from '../../../types/confirm-checkout-response.interface';
import { AxiosError } from 'axios';

const subscriptionService = SubscriptionCheckoutApiServiceFactory();

@Module({
  dynamic: true,
  name: 'purchase',
  store: appStoreFactory(),
  namespaced: true,
})
class PurchaseModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: ApiModelPurchaseInterface | null = null;
  public purchaseId: string | null = null;
  public selectedPackage: string | null = null;
  public selectedPurchasables: Array<string> = [];
  public selectedPaymentMethod: SubscriptionTypesPaymentMethodEnum | null =
    SubscriptionTypesPaymentMethodEnum.creditCard;
  public loadingFetchPurchase: AppStoreReadyStateEnum = AppStoreReadyStateEnum.loading;
  public loadingAddItem: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public couponFailed = false;
  public couponSuccess = false;
  public isCouponAPPLIED = false;
  public error: Error | null = null;
  public isWalletRecurring = false;
  public isLoadingSetPaymentMethod = false;
  public isPurchaseFailedDueToLessAmount = false;
  public isPurchasePackage = false;
  public isWalletSelected = false;
  public errorInitializeMessage = '';

  @Mutation
  private RESET_ERROR(): void {
    this.error = null;
  }

  @Mutation
  FETCH_ERROR(error: Error): void {
    this.error = error;
  }

  @Mutation
  public INITIALIZE_PURCHASE(): void {
    this.errorInitializeMessage = '';
    this.loadingState = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  public SET_PURCHASE_WALLET_ID(data: ApiModelPurchaseInterface): void {
    this.purchaseId = data.id;
  }

  @Mutation
  private INITIALIZE_PURCHASE_SUCCESS(data: ApiModelPurchaseInterface): void {
    this.data = data;
    this.purchaseId = data.id;
    this.selectedPaymentMethod = (data.payment_method.code as SubscriptionTypesPaymentMethodEnum) ?? null;
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.loadingFetchPurchase = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  SET_INITIALIZE_LOADING_STATE(state: AppStoreReadyStateEnum): void {
    this.loadingState = state;
  }

  @Mutation
  FETCH_ERROR_INITIALIZE(error: AxiosError): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;

    if (error.response?.status === 422 && this.isPurchasePackage) this.isPurchaseFailedDueToLessAmount = true;
    else this.errorInitializeMessage = getServerErrorMessage(error);
  }

  @Mutation
  private FETCH_PURCHASE(): void {
    this.loadingFetchPurchase = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  private FETCH_PURCHASE_SUCCESS(data: ApiModelPurchaseInterface): void {
    this.data = data;
    this.selectedPaymentMethod = (data.payment_method.code as SubscriptionTypesPaymentMethodEnum) ?? null;
    this.purchaseId = data.id;
    this.loadingFetchPurchase = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private ADD_ITEM(): void {
    this.loadingAddItem = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  private ADD_ITEM_SUCCESS(): void {
    this.loadingAddItem = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  ADD_ITEM_ERROR(): void {
    this.loadingAddItem = AppStoreReadyStateEnum.error;
  }

  @Mutation
  WALLET_SELECTED(isWalletSelected: boolean): void {
    this.isWalletSelected = isWalletSelected;
  }

  @Mutation
  FETCH_PURCHASE_ERROR(error: Error | null): void {
    this.error = error;
    this.loadingFetchPurchase = AppStoreReadyStateEnum.error;
  }

  @Mutation
  SET_IS_PURCHASE_FAILED_DUE_TO_LESS_AMOUNT(state: boolean): void {
    this.isPurchaseFailedDueToLessAmount = state;
  }

  @Action
  public setIsPurchaseFailedDueToLessAmount(state: boolean): void {
    this.SET_IS_PURCHASE_FAILED_DUE_TO_LESS_AMOUNT(state);
  }

  @Mutation
  SET_IS_PURCHASE_A_PACKAGE(state: boolean): void {
    this.isPurchasePackage = state;
  }

  @Action
  public setIsPurchaseAPackage(state: boolean): void {
    this.SET_IS_PURCHASE_A_PACKAGE(state);
  }

  @Action
  public setInitailzeLoadingState(state: AppStoreReadyStateEnum): void {
    this.SET_INITIALIZE_LOADING_STATE(state);
  }

  @Action
  public setPurchaseRequestLoadingState(state: AppStoreReadyStateEnum): void {
    if (state === AppStoreReadyStateEnum.loading) this.FETCH_PURCHASE();
    if (state === AppStoreReadyStateEnum.error) this.FETCH_PURCHASE_ERROR(null);
  }

  @Action
  public setPurchaseRequestLoadedState(data: ApiModelPurchaseInterface): void {
    this.FETCH_PURCHASE_SUCCESS(data);
  }

  @Action({ rawError: true })
  @Catch({
    errorHandler: (error: any, ctx) => {
      ctx.FETCH_ERROR_INITIALIZE(error);
    },
  })
  public async initializePurchase({
    purchasables,
    quantity = 1,
    meta = null,
  }: {
    purchasables: Array<string>;
    quantity: number;
    meta: unknown;
  }): Promise<SubscriptionApiPurchaseResponseInterface> {
    this.RESET_ERROR();
    this.INITIALIZE_PURCHASE();

    let purchasablesWithQuantity: Array<SubscriptionTypesSubscriptionPurchasableRequestInterface> = [];
    if (typeof meta === 'object') meta = JSON.stringify(meta);
    if (typeof purchasables === 'string') purchasablesWithQuantity.push({ id: purchasables, quantity, meta });
    else purchasablesWithQuantity = purchasables.map((purchasable) => ({ id: purchasable, quantity, meta }));

    const response = await subscriptionService.createPurchase(purchasablesWithQuantity);
    if (response.status === 'success') {
      this.INITIALIZE_PURCHASE_SUCCESS(response.data);
    }

    return response;
  }

  @Action({ rawError: true })
  @Catch({
    onError: (error: any, ctx) => {
      ctx.FETCH_ERROR(error);
      ctx.ADD_ITEM_ERROR();
    },
  })
  public async initializeWalletPurchase(purchasablesResponse: {
    purchasables: Array<string>;
    quantity: number;
    callbackUrl: string;
  }): Promise<SubscriptionApiPurchaseResponseInterface | void> {
    this.RESET_ERROR();
    let purchasablesWithQuantity: Array<SubscriptionTypesSubscriptionPurchasableRequestInterface> = [];
    if (typeof purchasablesResponse.purchasables === 'string')
      purchasablesWithQuantity.push({
        id: purchasablesResponse.purchasables,
        quantity: purchasablesResponse.quantity,
        callbackUrl: purchasablesResponse.callbackUrl,
      });
    else
      purchasablesWithQuantity = purchasablesResponse.purchasables.map((purchasable) => ({
        id: purchasable,
        quantity: purchasablesResponse.quantity,
        callbackUrl: purchasablesResponse.callbackUrl,
      }));

    const response = await subscriptionService.createPurchase(purchasablesWithQuantity);
    if (response.status === 'success') {
      this.SET_PURCHASE_WALLET_ID(response.data);
    }
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async addPurchaseItems(purchasable: string): Promise<any> {
    this.RESET_ERROR();

    if (this.purchaseId) {
      const purchasableWithQuantity: SubscriptionTypesSubscriptionCheckoutAddPurchaseItemRequestInterface = {
        ['purchasable_id']: purchasable,
        quantity: 1,
      };

      this.ADD_ITEM();
      const response = await subscriptionService.addPurchaseItems(purchasableWithQuantity, this.purchaseId);
      if (response.status === 'success') {
        this.SET_SELECTED_PURCHASABLES(response.data);
        this.ADD_ITEM_SUCCESS();
      }

      return response;
    } else {
      const purchasableWithQuantity: Array<SubscriptionTypesSubscriptionPurchasableRequestInterface> = [
        {
          id: purchasable,
          quantity: 1,
        },
      ];

      const response = await subscriptionService.createPurchase(purchasableWithQuantity);
      if (response.status === 'success') {
        this.FETCH_PURCHASE_SUCCESS(response.data);
        const purchasbles = response.data.invoice.items.map((item) => item.purchasable_id);
        this.SET_SELECTED_PURCHASABLES(purchasbles);
      }

      return response;
    }
  }

  @Mutation
  private SET_SELECTED_PACKAGE(event: string | null): void {
    this.selectedPackage = event;
  }

  @Action
  public setSelectedPackage(event: string | null): void {
    this.SET_SELECTED_PACKAGE(event);
  }

  @Mutation
  private SET_SELECTED_PURCHASABLES(event: Array<string>): void {
    this.selectedPurchasables = event;
  }

  @Action
  public setSelectedPurchasables(event: Array<string>): void {
    event = typeof event === 'string' ? [event] : event;
    this.SET_SELECTED_PURCHASABLES(event);
  }

  @Mutation
  private DELETE_SELECTED_PURCHASABLE(event: Array<ApiModelPurchaseInvoicePurchasedItemInterface>): void {
    this.selectedPurchasables = event.map((item) => item.purchasable_id);
  }

  @Mutation
  private SET_IS_RECURRING(event: boolean): void {
    if (this.data) this.data.meta['is_recurring'] = event;
    if (this.isWalletRecurring) this.isWalletRecurring = false;
  }

  @Mutation
  private SET_IS_WALLET_RECURRING(event: boolean): void {
    this.isWalletRecurring = event;
    if (this.data && this.data.meta['is_recurring']) this.data.meta['is_recurring'] = false;
  }

  @Mutation
  private SET_SELECTED_PAYMENT_METHOD(event: SubscriptionTypesPaymentMethodEnum): void {
    this.selectedPaymentMethod = event;
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async setIsRecurring(event: boolean): Promise<any> {
    if (this.purchaseId) {
      const response = await subscriptionService.setIsRecurring({ ['is_recurring']: event }, this.purchaseId);
      if (response.status === 'success') this.SET_IS_RECURRING(event);

      return response;
    }
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async setIsWalletRecurring(event: boolean): Promise<any> {
    //both wallet and credit card use the same endpoint & the separation of them is configured in the backend,
    //but they need separate booleans in the frontend
    if (this.purchaseId) {
      const response = await subscriptionService.setIsRecurring({ ['is_recurring']: event }, this.purchaseId);
      if (response.status === 'success') this.SET_IS_WALLET_RECURRING(event);

      return response;
    }
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async setSelectedPaymentMethod(
    event: SubscriptionTypesPaymentMethodEnum,
  ): Promise<Awaited<ReturnType<typeof subscriptionService.setPaymentMethod>> | null> {
    if (!this.purchaseId) return null;

    const response = await subscriptionService.setPaymentMethod({ ['payment_method']: event }, this.purchaseId);
    if (response.status === 'success' && !this.isWalletSelected) this.SET_SELECTED_PAYMENT_METHOD(event);

    return response;
  }

  @Mutation
  public TOGGLE_IS_LOADING_SET_PAYMENT_METHOD(): void {
    this.isLoadingSetPaymentMethod = !this.isLoadingSetPaymentMethod;
  }

  @Mutation
  private SET_COUPON(event: InputTextChangeEventInterface): void {
    if (this.data) this.data.coupon.code = event.value;
  }

  @Action
  public setCoupon(event: InputTextChangeEventInterface): void {
    this.SET_COUPON(event);
    this.RESET_COUPON_STATUSES();
  }

  @Mutation
  private SET_IS_COUPON_APPLIED(event: boolean): void {
    this.isCouponAPPLIED = event;
  }

  @Action
  public setIsCouponApplied(event: boolean): void {
    this.SET_IS_COUPON_APPLIED(event);
  }

  @Mutation
  private SET_COUPON_SUCCESS(event: boolean): void {
    this.couponSuccess = event;
  }

  @Action
  public setCouponSuccess(event: boolean): void {
    this.SET_COUPON_SUCCESS(event);
  }

  @Mutation
  private SET_COUPON_FAILED(event: boolean): void {
    this.couponFailed = event;
  }

  @Mutation
  private RESET_COUPON_STATUSES(): void {
    this.couponFailed = false;
    this.couponSuccess = false;
    this.isCouponAPPLIED = false;
  }

  @Action
  public setCouponFailed(event: boolean): void {
    this.SET_COUPON_FAILED(event);
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_PURCHASE_ERROR(error) })
  public async deleteCoupon(): Promise<any> {
    if (this.purchaseId && this.data?.coupon?.code) {
      this.FETCH_PURCHASE();
      const response = await subscriptionService.deleteCoupon(this.purchaseId);
      if (response.status === 'success') {
        this.RESET_COUPON_STATUSES();
        this.FETCH_PURCHASE_SUCCESS(response.data);
      }

      return response;
    }
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_PURCHASE_ERROR(error) })
  public async deletePurchaseItem(item: string): Promise<any> {
    if (this.purchaseId) {
      this.FETCH_PURCHASE();
      const response = await subscriptionService.deletePurchaseItem({ ['purchasable_id']: item }, this.purchaseId);
      if (response.status === 'success') {
        this.DELETE_SELECTED_PURCHASABLE(response.data?.invoice?.items);
        this.FETCH_PURCHASE_SUCCESS(response.data);
        !response.data.coupon.code && this.RESET_COUPON_STATUSES();
      }

      return response;
    }
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_PURCHASE_ERROR(error) })
  public async viewPurchase(): Promise<SubscriptionApiPurchaseResponseInterface | void> {
    if (this.purchaseId) {
      this.FETCH_PURCHASE();
      const response = await subscriptionService.viewPurchase(this.purchaseId);
      if (response.status === 'success') {
        this.SET_COUPON_FAILED(false);
        this.FETCH_PURCHASE_SUCCESS(response.data);
      }
    }
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_PURCHASE_ERROR(error) })
  public async viewPurchaseStatusWallet(purchaseId: string): Promise<SubscriptionApiPurchaseResponseInterface | void> {
    this.FETCH_PURCHASE();
    const response = await subscriptionService.viewPurchase(purchaseId);
    if (response.status === 'success') {
      this.SET_COUPON_FAILED(false);
      this.FETCH_PURCHASE_SUCCESS(response.data);
    }
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_PURCHASE_ERROR(error) })
  public async confirmPurchase(purchaseId: string): Promise<SupscriptionTypesConfirmCheckoutResponseInterface | any> {
    this.FETCH_PURCHASE();
    const response = await subscriptionService.confirmZidWalletPurchase(purchaseId);

    return response;
  }

  @Action({ rawError: true })
  public async confirmWalletTopup({ purchaseId, tapId }: { purchaseId: string; tapId: string }): Promise<any> {
    this.FETCH_PURCHASE();

    return subscriptionService
      .confirmWalletTopup(purchaseId, tapId)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        this.FETCH_ERROR(err);
        return err.response;
      });
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_PURCHASE_ERROR(error) })
  public async confirm(purchaseId: string): Promise<SupscriptionTypesConfirmCheckoutResponseInterface | any> {
    this.FETCH_PURCHASE();
    const response = await subscriptionService.confirm(purchaseId);

    return response;
  }
}

export const PurchaseStoreModule = getModule(PurchaseModule);
