import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../route.enum';

function isRouteAllowed(to: Route): boolean {
  return [
    RoutingRouteEnum.subscriptionsSuspended,
    RoutingRouteEnum.home,
    RoutingRouteEnum.subscriptionsCheckout,
    RoutingRouteEnum.completeStoreInfo,
    RoutingRouteEnum.subscriptionsCheckoutThank,
  ].includes(to.name as RoutingRouteEnum);
}

export function suspendedGuard(to: Route, next: Function) {
  if (!isRouteAllowed(to)) next({ name: RoutingRouteEnum.home });
  else next();
}
