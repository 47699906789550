import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ApiBaseDataResponseObjectType, ApiBaseMessageResponseObjectType } from '../../api/types';
import { MazeedApiServiceFactory } from '../api/service-factory';
import { MazeedTypesActivationRequirementsDataType } from '../types/Activation/activation_reuirements_data.type';
import { MazeedApiRequestInterface, MazeedSettingsOption, MazeedNotifyMeInterface } from '../types/mazeed.interface';
import { MazeedTypesStoreSummaryDataType } from '../types/store-summary-data.type';
import {
  MazeedTypesActivationBankAccountData,
  MazeedTypesActivationContentData,
  MazeedTypesActivationStoreInfoData,
  MazeedTypesActivationStoreProfileData,
} from '../types/Activation/store_profile_data.type';
import { MazeedTypesActivationIndustriesListType } from '../types/Activation/activation_industries.type';
import {
  MazeedTypeActivationBankListType,
  MazeedTypeAddNewBankAccountType,
  MazeedTypeAvailableBanksType,
  MazeedTypeBankAccountType,
  MazeedTypeCreateBankAccountType,
} from '../types/Activation/activation_banks.type';
import { MazeedTypesActivationAgreementsDataType } from '../types/Activation/activation_agreements_data.type';
import { MazeedTypesActivationSubmitBodyType } from '../types/Activation/activation_submit_body.type';
import { I18nLocalizedStringMapType } from '../../i18n/types';
import { MazeedEnumsStoreFormKeysEnum } from '../enums/store-form-keys.enum';
import { MazeedEnumsStoreSummaryEnum } from '../enums/store-summary-status.enum';
import { ProductsApiFiltersInterface } from '../../products/api/filters.interface';
import { ProductsApiResponseInterface } from '../../products/api/response.interface';
import { updateTheValuesOFStoreProfileObject } from '../helpers/store_form_helpers.helper';
import { ApiModelProductInterface } from '../../api/model/product.interface';
import {
  MazeedTypesResellerSettlementsDataType,
  MazeedTypesResellerSettlementsPaginationType,
} from '../types/mazeed_reseller_settlement.type';
import { MazeedTypesSettlementInvoiceType } from '../types/mazeed_settlement_invoice.type';
import { revalidate } from '../../common/helpers/revalidate/revalidate';

import appStoreFactory from '../../app/store/factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import Catch from '../../common/decorators/catch-error';
import { MazeedTypesProductMazeedInfo } from '../types/mazeed_product.type';

const mazeedService = MazeedApiServiceFactory();

@Module({
  dynamic: true,
  name: 'mazeed',
  store: appStoreFactory(),
  namespaced: true,
})
class Mazeed extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public updateSettingsLoadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: MazeedSettingsOption[] | null = null;
  public storeSummary: MazeedTypesStoreSummaryDataType | null = null;
  public industriesList: MazeedTypesActivationIndustriesListType = [];
  public banksList: MazeedTypeActivationBankListType = [];
  public availableBankAccountsList: Array<MazeedTypeBankAccountType> = [];
  public storeProfileData: MazeedTypesActivationStoreProfileData | null = null;
  public productsList: ProductsApiResponseInterface | null = null;
  public settlementsData: MazeedTypesResellerSettlementsDataType | null = null;
  public storeProfileErrors: Array<string> = [];
  public agreementsCheckboxValue = false;
  public selectedProductsIds: Array<string> = [];
  public notified = false;
  public error: Error | null = null;

  get checkStoreInSaudiArabia(): boolean {
    return this.storeSummary?.store_info.country_code == 'SA';
  }

  get checkIfAllowToActivate(): boolean {
    const status = this.storeSummary?.status || '';
    return !status || status == MazeedEnumsStoreSummaryEnum.draft || status == MazeedEnumsStoreSummaryEnum.rejected;
  }

  @Mutation
  private FETCH(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS(data: MazeedSettingsOption[]): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = data;
  }

  @Mutation
  private UPDATE_LOADING_STATE(state: AppStoreReadyStateEnum): void {
    this.loadingState = state;
  }

  @Mutation
  private UPDATE_SETTINGS(): void {
    this.updateSettingsLoadingState = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private UPDATE_SETTINGS_SUCCESS(data: MazeedSettingsOption[]): void {
    this.updateSettingsLoadingState = AppStoreReadyStateEnum.loaded;
    this.data = data;
  }

  @Mutation
  private SET_STORE_SUMMARY(data: MazeedTypesStoreSummaryDataType): void {
    this.storeSummary = data;
  }

  @Mutation
  private SET_INDUSTRIE_LIST(data: MazeedTypesActivationIndustriesListType): void {
    this.industriesList = data;
  }

  @Mutation
  private SET_BANKS_LIST(data: MazeedTypeActivationBankListType): void {
    this.banksList = data;
  }

  @Mutation
  private SET_AVAILABLE_BANK_ACCOUNTS_LIST(data: MazeedTypeAvailableBanksType): void {
    this.availableBankAccountsList = data.banksAccounts;
  }

  @Mutation
  private SET_AGREEMENTS_CHECK_BOX_VALUE(data: boolean): void {
    this.agreementsCheckboxValue = data;
  }

  @Mutation
  private SET_SELECTED_PRODUCTS_IDS(data: Array<string>): void {
    this.selectedProductsIds = data;
  }

  @Mutation
  private SET_STORE_PROFILE_DATA(data: MazeedTypesActivationStoreProfileData): void {
    this.storeProfileData = data;
    if (!Boolean(this.storeProfileData.isContactOperations))
      this.storeProfileData.contactOperations = { ...this.storeProfileData.contactOwner };
  }

  @Mutation
  private SET_SETTLEMENTS_DATA(data: MazeedTypesResellerSettlementsDataType): void {
    this.settlementsData = data;
  }

  @Mutation
  private UPDATE_OBJECT_DATA_IN_STORE_PROFILE_DATA({
    parentKey,
    key,
    value,
  }: {
    parentKey: MazeedEnumsStoreFormKeysEnum;
    key: MazeedEnumsStoreFormKeysEnum;
    value: string;
  }): void {
    if (this.storeProfileData) {
      switch (parentKey) {
        case MazeedEnumsStoreFormKeysEnum.contactOwner:
          this.storeProfileData.contactOwner[key as keyof MazeedTypesActivationContentData] = value;
          break;
        case MazeedEnumsStoreFormKeysEnum.contactOperations:
          this.storeProfileData.contactOperations[key as keyof MazeedTypesActivationContentData] = value;
          break;
        case MazeedEnumsStoreFormKeysEnum.contactSupport:
          this.storeProfileData.contactSupport[key as keyof MazeedTypesActivationContentData] = value;
          break;
        case MazeedEnumsStoreFormKeysEnum.storeInfo:
          this.storeProfileData.store_info[key as keyof MazeedTypesActivationStoreInfoData] = value as string &
            I18nLocalizedStringMapType<string | File>;
          break;
        case MazeedEnumsStoreFormKeysEnum.bankAccount:
          if (key == MazeedEnumsStoreFormKeysEnum.bankId) {
            this.storeProfileData.bankAccount = { ...this.storeProfileData.bankAccount, bankId: Number(value) };
          } else {
            if (this.storeProfileData.bankAccount) {
              this.storeProfileData.bankAccount[key as keyof MazeedTypesActivationBankAccountData] = value as string &
                I18nLocalizedStringMapType<string | File>;
            }
          }
          break;
      }
    }
  }

  @Mutation
  private UPDATE_NORMAL_DATA_IN_STORE_PROFILE_DATA({
    key,
    value,
  }: {
    key: MazeedEnumsStoreFormKeysEnum;
    value: string | boolean;
  }): void {
    if (this.storeProfileData) {
      switch (key) {
        case MazeedEnumsStoreFormKeysEnum.mazeedIndustryId:
          this.storeProfileData = { ...this.storeProfileData, mazeedIndustryId: value.toString() };
          break;
        case MazeedEnumsStoreFormKeysEnum.description:
          this.storeProfileData.description = value.toString();
          break;
        case MazeedEnumsStoreFormKeysEnum.isContactOperations:
          this.storeProfileData = { ...this.storeProfileData, isContactOperations: Boolean(value) };
          if (!Boolean(value)) {
            this.storeProfileData.contactOperations = { ...this.storeProfileData.contactOwner };
          }
          break;
      }
    }
  }

  @Mutation
  private SET_PRODUCTS_LIST(products: ProductsApiResponseInterface): void {
    this.productsList = products;
  }

  @Mutation
  private SET_PROFILE_STORE_DATA_ERRORS(data: Array<string>): void {
    this.storeProfileErrors = data;
  }

  @Mutation
  private UPDATE_PRODUCT_ACCEPTANCE_STATUS(productId: string): void {
    if (this.productsList?.products.data) {
      const newUpdateProducts = this.productsList?.products.data.map((product: ApiModelProductInterface) => {
        if (product.id == productId) {
          product['mazeed_info'] = {
            ...product.mazeed_info,
            status: MazeedEnumsStoreSummaryEnum.inReview,
          };
        }
        return product;
      }) as Array<ApiModelProductInterface>;
      this.productsList.products.data = newUpdateProducts;
    }
  }

  @Mutation
  private UPDATE_PRODUCT_STATUS(productId: string): void {
    if (this.productsList?.products.data) {
      const newUpdateProducts = this.productsList?.products.data.map((product: ApiModelProductInterface) => {
        if (product.id == productId) {
          product['mazeed_info'] = {
            ...product.mazeed_info,
            isActive: !product.mazeed_info.isActive,
            status: !product.mazeed_info.isActive
              ? MazeedEnumsStoreSummaryEnum.active
              : MazeedEnumsStoreSummaryEnum.disabled,
          };
        }
        return product;
      }) as Array<ApiModelProductInterface>;
      this.productsList.products.data = newUpdateProducts;
    }
  }

  @Action
  @Catch()
  public setSettingsLoadingState(state: AppStoreReadyStateEnum): void {
    this.UPDATE_LOADING_STATE(state);
  }

  @Action({ rawError: true })
  @Catch()
  public async fetchMazeedSettings(): Promise<void> {
    this.FETCH();
    const response = await mazeedService.getMazeedSettings();
    this.FETCH_SUCCESS(response.data.payload);
  }

  @Action({ rawError: true })
  @Catch()
  public async updateMazeedSettings(data: MazeedApiRequestInterface): Promise<void> {
    this.UPDATE_SETTINGS();
    const response = await mazeedService.updateMazeedSettings(data);
    this.UPDATE_SETTINGS_SUCCESS(response.data.payload);
  }

  @Action({ rawError: true })
  @Catch()
  public async notifymeMazeed(data: MazeedNotifyMeInterface): Promise<ApiBaseMessageResponseObjectType> {
    return await mazeedService.registerInMazeedAnnouncement(data);
  }

  @Action
  @Catch()
  public async getActivationRequirementsChecks(): Promise<
    ApiBaseDataResponseObjectType<MazeedTypesActivationRequirementsDataType>
  > {
    return await mazeedService.getActivationRequirementsChecks();
  }

  @Action
  @Catch()
  public async getStoreProfileData(): Promise<void> {
    const response = await mazeedService.getStoreProfileData();
    this.SET_STORE_PROFILE_DATA(updateTheValuesOFStoreProfileObject(response.data.data));

    revalidate(response, (fresh: typeof response.data) =>
      this.SET_STORE_PROFILE_DATA(updateTheValuesOFStoreProfileObject(fresh.data)),
    );
  }

  @Action
  @Catch()
  public updateContactOwnerStoreProfile({ key, value }: { key: MazeedEnumsStoreFormKeysEnum; value: string }): void {
    this.UPDATE_OBJECT_DATA_IN_STORE_PROFILE_DATA({ parentKey: MazeedEnumsStoreFormKeysEnum.contactOwner, key, value });
  }

  @Action
  @Catch()
  public updateStoreInfoInStoreProfile({ key, value }: { key: MazeedEnumsStoreFormKeysEnum; value: string }): void {
    this.UPDATE_OBJECT_DATA_IN_STORE_PROFILE_DATA({ parentKey: MazeedEnumsStoreFormKeysEnum.storeInfo, key, value });
  }

  @Action
  @Catch()
  public updateBankAccountInStoreProfile({ key, value }: { key: MazeedEnumsStoreFormKeysEnum; value: string }): void {
    this.UPDATE_OBJECT_DATA_IN_STORE_PROFILE_DATA({ parentKey: MazeedEnumsStoreFormKeysEnum.bankAccount, key, value });
  }

  @Action
  @Catch()
  public updateContactOperationsInStoreProfile({
    key,
    value,
  }: {
    key: MazeedEnumsStoreFormKeysEnum;
    value: string;
  }): void {
    this.UPDATE_OBJECT_DATA_IN_STORE_PROFILE_DATA({
      parentKey: MazeedEnumsStoreFormKeysEnum.contactOperations,
      key,
      value,
    });
  }

  @Action
  @Catch()
  public updateContactSupportInStoreProfile({
    key,
    value,
  }: {
    key: MazeedEnumsStoreFormKeysEnum;
    value: string;
  }): void {
    this.UPDATE_OBJECT_DATA_IN_STORE_PROFILE_DATA({
      parentKey: MazeedEnumsStoreFormKeysEnum.contactSupport,
      key,
      value,
    });
  }

  @Action
  @Catch()
  public updateNormalDataInStoreProfile({
    key,
    value,
  }: {
    key: MazeedEnumsStoreFormKeysEnum;
    value: string | boolean;
  }): void {
    this.UPDATE_NORMAL_DATA_IN_STORE_PROFILE_DATA({ key, value });
  }

  @Action
  @Catch()
  public async getStoreSummary(): Promise<void> {
    const response = await mazeedService.getStoreSummary();
    if (response.data) this.SET_STORE_SUMMARY(response.data.data);

    revalidate(response, (fresh: typeof response.data) => {
      if (fresh) this.SET_STORE_SUMMARY(fresh.data);
    });
  }

  @Action
  @Catch()
  public async updateStoreProfileData(
    data: MazeedTypesActivationStoreProfileData,
  ): Promise<MazeedTypesActivationStoreProfileData | void> {
    const response = await mazeedService.updateStoreProfileData(data);
    if (response) return response.data;
  }

  @Action
  @Catch()
  public async getAllIndustries(): Promise<void> {
    const response = await mazeedService.getAllIndustries();
    this.SET_INDUSTRIE_LIST(response.data);
  }

  @Action
  @Catch()
  public async getAllSaudiBanks(): Promise<void> {
    const response = await mazeedService.getAllSaudiBanks();
    this.SET_BANKS_LIST(response.data);
  }

  @Action
  @Catch()
  public async getAllAvailableBankAccounts(): Promise<void> {
    const response = await mazeedService.getAllAvailableBankAccounts();
    this.SET_AVAILABLE_BANK_ACCOUNTS_LIST(response.data);
  }

  @Action
  @Catch()
  public async getAgreements(): Promise<MazeedTypesActivationAgreementsDataType> {
    const response = await mazeedService.getAgreements();
    return response.data;
  }

  @Action
  @Catch()
  public changeAgreementsCheckboxValue(data: boolean): void {
    this.SET_AGREEMENTS_CHECK_BOX_VALUE(data);
  }

  @Action
  @Catch()
  public async getMazeedProducts(filters: ProductsApiFiltersInterface): Promise<void> {
    const response = await mazeedService.getMazeedProducts(filters);
    this.SET_PRODUCTS_LIST(response.data);

    const selectedProductsIds = response.data.products.data
      .filter((product) => product.is_added_to_mazeed)
      .map((product) => product.id);
    if (selectedProductsIds.length) this.updateSelectedProducts(selectedProductsIds);
  }

  @Action
  @Catch()
  public updateSelectedProducts(productsIds: Array<string>): void {
    this.SET_SELECTED_PRODUCTS_IDS(productsIds);
  }

  @Action
  @Catch()
  public async updateProfileStoreFormErrors(data: Array<string>): Promise<void> {
    this.SET_PROFILE_STORE_DATA_ERRORS(data);
  }

  @Action
  @Catch()
  public async mazeedSubmitForReview(
    data: MazeedTypesActivationSubmitBodyType,
  ): Promise<MazeedTypesActivationStoreProfileData> {
    const response = await mazeedService.mazeedSubmitForReview(data);
    return response.data;
  }

  @Action
  @Catch()
  public async addProductsToMazeed({
    productIds,
    submitStatus,
  }: {
    productIds: Array<string>;
    submitStatus: boolean;
  }): Promise<MazeedTypesProductMazeedInfo> {
    const response = await mazeedService.addProductsToMazeed(productIds, submitStatus);
    return response.data?.[0];
  }

  @Action
  @Catch()
  public async mazeedAddNewBankAccount(): Promise<MazeedTypeCreateBankAccountType> {
    const payload: MazeedTypeAddNewBankAccountType =
      this.storeProfileData && this.storeProfileData.bankAccount
        ? {
            ['bank_id']: this.storeProfileData.bankAccount.bankId?.toString(),
            ['account_number']: this.storeProfileData.bankAccount.accountNumber,
            iban: this.storeProfileData.bankAccount.iban,
            ['beneficiary_name']: this.storeProfileData.bankAccount.beneficiary,
            ['is_visible']: 1,
          }
        : {};
    const response = await mazeedService.mazeedAddNewBankAccount(payload);
    return response.data;
  }

  @Action
  @Catch()
  public async mazeedCheckIban(iban: string): Promise<{ is_valid: boolean }> {
    const response = await mazeedService.mazeedCheckIban(iban);
    return response.data;
  }

  @Action
  @Catch()
  public updateProductAcceptanceStatus(productId: string): void {
    this.UPDATE_PRODUCT_ACCEPTANCE_STATUS(productId);
  }

  @Action
  @Catch()
  public updateProductStatus(productId: string): void {
    this.UPDATE_PRODUCT_STATUS(productId);
  }

  @Action
  @Catch()
  public async mazeedActivateProducts(productIds: Array<string>): Promise<{ data: {} }> {
    const response = await mazeedService.mazeedActivateProducts(productIds);
    return response.data;
  }

  @Action
  @Catch()
  public async mazeedDisableProducts(productIds: Array<string>): Promise<{ data: {} }> {
    const response = await mazeedService.mazeedDisableProducts(productIds);
    return response.data;
  }

  @Action
  @Catch()
  public async mazeedAllResellerSettlements(page?: number): Promise<MazeedTypesResellerSettlementsPaginationType> {
    const response = await mazeedService.mazeedAllResellerSettlements(page);
    return response.data;
  }

  @Action
  @Catch()
  public async mazeedAnalysisResellerSettlements(): Promise<void> {
    const response = await mazeedService.mazeedAnalysisResellerSettlements();
    this.SET_SETTLEMENTS_DATA(response.data);
  }

  @Action
  @Catch()
  public async mazeedResellerSettlementInvoice(invoiceId: string): Promise<MazeedTypesSettlementInvoiceType> {
    const response = await mazeedService.mazeedResellerSettlementInvoice(invoiceId);
    return response.data;
  }

  @Action
  @Catch()
  public async mazeedDownloadResellersSettlements(): Promise<Blob> {
    const response = await mazeedService.mazeedDownloadResellersSettlements();
    return response;
  }
}

export const MazeedModule = getModule(Mazeed);
