import { ValidateIbanApiResponseInterface, ValidateIbanData } from './../types/BankAccount.interface';
import ApiClient from '../../api/client';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import { ApiBaseDataResponseObjectType, ApiBaseResponseObjectType, ApiResponseType } from '../../api/types';
import { urlQueryFormat } from '../../common/helpers/url/query/format';
import { BankAccountApiResponseInterface } from '../types/BankAccount.interface';
import { ZidpayTypesPaginatedDepositItemsType } from '../types/deposit-item.interface';
import ZidpayExportedDepositsType from '../types/exported-deposits.interface';

import { ZidpayTypesMerchantStatsInterface } from '../types/merchant-stats.interface';
import { MerchantProfileApiResponseInterface, MerchantProfileEnteredFields } from '../types/MerchantProfile.interface';
import { ZidpayPaginatedDepositsType, ZidpayPaginatedNoneDepositsType } from '../types/paginated-deposits.type';
import { ZidPayPaginatedPaymentLinksType, ZidpayPaginatedTransactionsType } from '../types/paginated-transactions.type';
import { ZidpayTypesPaymentInterface, ZidpayTypesPaymentRefundInterface } from '../types/payment.interface';
import ZidpayApiFiltersTransactions, { ZidpayApiFiltersPaymentLink } from './filters/transactions.interface';
import { transformTransactionsFiltersToRequestParams } from './helpers/transform-transactions-filters';
import { zidpayApiUrls } from './urls';
import { MerchantDetailsApiResponseInterface, NafazDataApiResponseInterface } from '../types/MerchantDetails.interface';
import { NetworksApiResponseInterface, Package } from '../types/Networks.interface';
import { ZidpayCountriesApiResponseInterface } from '../types/ZidpayCountries.interface';
import { homeApiUrls } from '../../home/api/urls';
import { NotifyMeInterface } from '../types/NotifyMe.interface';
import { UpdateMerchant } from '../types/UpdateMerchant';
import { PaymentLinksTransactionDetailInterface } from '../types/PaymentLinksTransactionsInterface';
import { PaymentLinkSendingTypeEnum } from '../types/PaymentLinkSendingType.enum';
import { PaymentLinkActivatedReminderData } from '../types/PaymentLink.interface';
import { ZidpayBankResponse } from '../types/ZidpayBank.interface';
import { CreateRefund } from '../types/CreateRefund.interface';
import { CountryCode } from '../countries/shared/CountryValidation/CountryValidation.enum';
import { DownloadAttachment } from '../types/MerchantDetailsAttachment';
import { BulkExportData } from '../types/DepositeBulkExportType.enum';
import { ZidpayMerchantNotificationsInterface } from '../types/merchant-notifications.interface';
import ApiModelDeliveryStatusInterface from '../../api/model/delivery-status.interface';
// import { ApiBaseResponseType } from '../../api/types';

export class ZidpayApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getCountries(): Promise<ZidpayCountriesApiResponseInterface> {
    return this.apiClient
      .request<ZidpayCountriesApiResponseInterface>(ApiHttpMethodEnum.get, zidpayApiUrls.getCountries)
      .then((response) => response.data);
  }

  public async getTransactions(
    filters: ZidpayApiFiltersTransactions,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidpayPaginatedTransactionsType>>> {
    const params = urlQueryFormat({
      ...transformTransactionsFiltersToRequestParams(filters),
    });

    const response = this.apiClient.request<ApiBaseDataResponseObjectType<ZidpayPaginatedTransactionsType>>(
      ApiHttpMethodEnum.get,
      zidpayApiUrls.getTransactions,
      {
        params,
        cacheable: true,
      },
    );

    return response;
  }

  public async exportTransactions(filters: ZidpayApiFiltersTransactions): Promise<Blob> {
    const params = urlQueryFormat({
      ...transformTransactionsFiltersToRequestParams(filters),
    });

    return this.apiClient
      .request<Blob>(ApiHttpMethodEnum.get, zidpayApiUrls.exportTransactions, {
        params,
        responseType: 'blob',
      })
      .then((response) => {
        return response.data;
      });
  }

  public async getDeposits(
    filters: ZidpayApiFiltersTransactions,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidpayPaginatedDepositsType>>> {
    const params = urlQueryFormat({
      ...transformTransactionsFiltersToRequestParams(filters),
    });

    const response = this.apiClient.request<ApiBaseDataResponseObjectType<ZidpayPaginatedDepositsType>>(
      ApiHttpMethodEnum.get,
      zidpayApiUrls.getDeposits,
      {
        params,
        cacheable: true,
      },
    );

    return response;
  }
  public async getNoneDeposits(
    filters: ZidpayApiFiltersTransactions,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidpayPaginatedNoneDepositsType>>> {
    const params = urlQueryFormat({
      ...transformTransactionsFiltersToRequestParams(filters),
    });

    const response = this.apiClient.request<ApiBaseDataResponseObjectType<ZidpayPaginatedNoneDepositsType>>(
      ApiHttpMethodEnum.get,
      zidpayApiUrls.getNoneDeposits,
      {
        params,
        cacheable: true,
      },
    );

    return response;
  }

  public async getDeposit(
    depositId: string,
    filters: ZidpayApiFiltersTransactions,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidpayTypesPaginatedDepositItemsType>>> {
    const url = zidpayApiUrls.getDeposit.replace('{depositId}', depositId);

    const params = urlQueryFormat({
      ...transformTransactionsFiltersToRequestParams(filters),
    });

    const response = this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidpayTypesPaginatedDepositItemsType>>(ApiHttpMethodEnum.get, url, {
        params,
      })
      .then((response) => response);

    return response;
  }

  public async bulkExportDepositsExcel(
    data: BulkExportData,
  ): Promise<ApiBaseDataResponseObjectType<{ detail?: string }>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<{ detail?: string }>>(
        ApiHttpMethodEnum.get,
        zidpayApiUrls.bulkExportDeposits,
        {
          params: {
            ...data,
          },
        },
      )
      .then((response) => response.data);
  }
  public async exportDepositsExcel(
    depositId: string,
  ): Promise<ApiBaseDataResponseObjectType<ZidpayExportedDepositsType>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidpayExportedDepositsType>>(
        ApiHttpMethodEnum.get,
        zidpayApiUrls.exportDepositsExcel,
        {
          params: {
            depositId,
          },
        },
      )
      .then((response) => response.data);
  }

  public async exportDepositsPDF(depositId: string): Promise<Blob> {
    return this.apiClient
      .request<Blob>(ApiHttpMethodEnum.get, zidpayApiUrls.exportDepositsPDF, {
        params: {
          depositId,
        },
        responseType: 'blob',
      })
      .then((response) => response.data);
  }

  public async getPaymentDetails(
    referenceId: string,
  ): Promise<ApiBaseDataResponseObjectType<ZidpayTypesPaymentInterface>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidpayTypesPaymentInterface>>(
        ApiHttpMethodEnum.post,
        zidpayApiUrls.getPaymentDetails,
        {
          data: {
            paymentReferenceId: referenceId,
          },
        },
      )
      .then((response) => response.data);
  }

  public async getBanks(): Promise<ApiBaseDataResponseObjectType<ZidpayBankResponse>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidpayBankResponse>>(ApiHttpMethodEnum.get, zidpayApiUrls.getBanks)
      .then((response) => response.data);
  }

  public async createRefund(
    refundInfo: CreateRefund,
  ): Promise<ApiBaseDataResponseObjectType<ZidpayTypesPaymentRefundInterface>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidpayTypesPaymentRefundInterface>>(
        ApiHttpMethodEnum.post,
        zidpayApiUrls.createRefund,
        {
          data: refundInfo,
        },
      )
      .then((response) => response.data);
  }

  public async getMerchantProfile(): Promise<MerchantProfileApiResponseInterface> {
    return this.apiClient
      .request<MerchantProfileApiResponseInterface>(ApiHttpMethodEnum.get, zidpayApiUrls.merchantProfile)
      .then((result) => result.data);
  }

  public async getMerchantStats(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ZidpayTypesMerchantStatsInterface>>
  > {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidpayTypesMerchantStatsInterface>>(
        ApiHttpMethodEnum.get,
        zidpayApiUrls.getMerchantStats,
        { cacheable: true },
      )
      .then((result) => result);
  }

  public async getMerchantNotifications(): Promise<ApiResponseType<Array<ZidpayMerchantNotificationsInterface>>> {
    return this.apiClient.request(ApiHttpMethodEnum.get, zidpayApiUrls.getMerchantNotifications).then((response) => {
      return response.data;
    });
  }

  public async updateMerchantNotifications(data: { NotificationId: number; ActionType: number }): Promise<any> {
    return this.apiClient
      .request<any>(ApiHttpMethodEnum.post, zidpayApiUrls.updateMerchantNotifications, { data })
      .then((result) => result.data);
  }

  public async getMerchantPackages(): Promise<ApiResponseType<Package>> {
    return this.apiClient.request(ApiHttpMethodEnum.get, zidpayApiUrls.getMerchantPackages).then((response) => {
      return response.data;
    });
  }

  public async getBankInfo(countryCode: CountryCode | string = ''): Promise<BankAccountApiResponseInterface> {
    return this.apiClient
      .request<BankAccountApiResponseInterface>(ApiHttpMethodEnum.get, zidpayApiUrls.bankInfo, {
        params: {
          countryCode,
        },
      })
      .then((result) => result.data);
  }

  public async validateIban(data: ValidateIbanData): Promise<ValidateIbanApiResponseInterface> {
    return this.apiClient
      .request<ValidateIbanApiResponseInterface>(ApiHttpMethodEnum.post, zidpayApiUrls.validateIban, { data })
      .then((result) => result.data);
  }

  public async postActivateMerchant(data: MerchantProfileEnteredFields, isReActivate: boolean): Promise<any> {
    return this.apiClient
      .request<any>(ApiHttpMethodEnum.post, zidpayApiUrls.activateMerchant, {
        data: {
          ...data,
          isReActivate,
        },
      })
      .then((result) => result.data);
  }

  public async getMerchantDetails(): Promise<MerchantDetailsApiResponseInterface> {
    return this.apiClient
      .request<MerchantDetailsApiResponseInterface>(ApiHttpMethodEnum.get, zidpayApiUrls.merchantDetails)
      .then((result) => result.data);
  }

  public async getNafazCode(): Promise<NafazDataApiResponseInterface> {
    return this.apiClient
      .request<NafazDataApiResponseInterface>(ApiHttpMethodEnum.get, zidpayApiUrls.nafazCode)
      .then((result) => result.data);
  }

  public async getMerchantAttachments(data: DownloadAttachment): Promise<Blob> {
    return this.apiClient
      .request<Blob>(ApiHttpMethodEnum.post, zidpayApiUrls.downloadMerchantAttachments, {
        data: data,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/octet-stream',
        },
      })
      .then((res) => res.data);
  }
  public async getNetworks(): Promise<ApiResponseType<NetworksApiResponseInterface>> {
    const response = this.apiClient.request<NetworksApiResponseInterface>(
      ApiHttpMethodEnum.get,
      zidpayApiUrls.networks,
      {
        cacheable: true,
      },
    );

    return response;
  }
  public async enableNetwork(name: string): Promise<ApiBaseDataResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.post, zidpayApiUrls.enableNetwork, { data: { name } })
      .then((result) => result.data);
  }
  public async disableNetwork(name: string): Promise<ApiBaseDataResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.post, zidpayApiUrls.disableNetwork, { data: { name } })
      .then((result) => result.data);
  }
  public async enableZidpay(): Promise<ApiBaseDataResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.post, zidpayApiUrls.enableZidpay)
      .then((result) => result.data);
  }
  public async updateMerchant(merchant: UpdateMerchant): Promise<ApiBaseDataResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.put, zidpayApiUrls.updateMerchant, { data: merchant })
      .then((result) => result.data);
  }
  public async disableZidpay(): Promise<ApiBaseDataResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.post, zidpayApiUrls.disableZidpay)
      .then((result) => result.data);
  }
  public activatePaymentLink(type: PaymentLinkSendingTypeEnum): Promise<ApiBaseDataResponseObjectType> {
    const url =
      type === PaymentLinkSendingTypeEnum.Email
        ? zidpayApiUrls.activatePaymentLinkViaEmail
        : zidpayApiUrls.activatePaymentLinkViaSms;
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.post, url)
      .then((result) => result.data);
  }
  public async deactivatePaymentLink(type: PaymentLinkSendingTypeEnum): Promise<ApiBaseDataResponseObjectType> {
    const url =
      type === PaymentLinkSendingTypeEnum.Email
        ? zidpayApiUrls.deactivatePaymentLinkViaEmail
        : zidpayApiUrls.deactivatePaymentLinkViaSms;
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.post, url)
      .then((result) => result.data);
  }
  public async activatePaymentLinkReminder(
    data: PaymentLinkActivatedReminderData,
  ): Promise<ApiBaseDataResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.post, zidpayApiUrls.activatePaymentLinkReminder, {
        data,
      })
      .then((result) => result.data);
  }

  public async deactivatePaymentLinkReminder(): Promise<ApiBaseDataResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.post, zidpayApiUrls.deactivatePaymentLinkReminder)
      .then((result) => result.data);
  }
  public async registerInAnnouncement(data: NotifyMeInterface): Promise<any> {
    return this.apiClient
      .request<any>(ApiHttpMethodEnum.post, homeApiUrls.featureAnnouncements, {
        data: {
          email: data.email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          feature_code: data.countryCode,
        },
      })
      .then((response) => response.data);
  }

  public async getPaymentLinks(
    filters: ZidpayApiFiltersPaymentLink,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidPayPaginatedPaymentLinksType>>> {
    const params = urlQueryFormat({
      ...transformTransactionsFiltersToRequestParams(filters),
    });

    const response = this.apiClient.request<ApiBaseDataResponseObjectType<ZidPayPaginatedPaymentLinksType>>(
      ApiHttpMethodEnum.get,
      zidpayApiUrls.getPaymentLinks,
      {
        params,
        cacheable: true,
      },
    );

    return response;
  }

  public async getOrderDeliveryStatus(shipmentId: string): Promise<ApiModelDeliveryStatusInterface> {
    const url = zidpayApiUrls.getDeliveryStatus.replace('{shipmentId}', shipmentId);
    const data = new FormData();
    data.append('shipmentId', shipmentId);

    const response = await this.apiClient.request<ApiModelDeliveryStatusInterface>(ApiHttpMethodEnum.get, url, {
      data,
    });
    return response.data;
  }

  public async exportPaymentLinks(filters: ZidpayApiFiltersPaymentLink): Promise<Blob> {
    const params = urlQueryFormat({
      ...transformTransactionsFiltersToRequestParams(filters),
    });

    return this.apiClient
      .request<Blob>(ApiHttpMethodEnum.get, zidpayApiUrls.exportPaymentLinks, {
        params,
        responseType: 'blob',
      })
      .then((response) => {
        return response.data;
      });
  }

  public async getPaymentLinkDetails(
    referenceId: string,
  ): Promise<ApiBaseDataResponseObjectType<PaymentLinksTransactionDetailInterface>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<PaymentLinksTransactionDetailInterface>>(
        ApiHttpMethodEnum.post,
        zidpayApiUrls.getPaymentLinkDetails,
        {
          data: {
            paymentLinkReferenceId: referenceId,
          },
        },
      )
      .then((response) => response.data);
  }

  public async reactivatePaymentLink(referenceId: string, newExpirationDate: Date): Promise<any> {
    return this.apiClient.request(ApiHttpMethodEnum.post, zidpayApiUrls.reactivatePaymentLink, {
      data: {
        referenceId,
        newExpirationDate,
      },
    });
  }
  public async cancelPaymentLink(referenceId: string): Promise<any> {
    return this.apiClient.request(ApiHttpMethodEnum.post, zidpayApiUrls.cancelPaymentLink, {
      data: {
        referenceId,
      },
    });
  }

  public async dismissRejectedRefundsNotification(): Promise<ApiBaseResponseObjectType> {
    return this.apiClient
      .request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.post, zidpayApiUrls.dismissRejectedRefundsNotification)
      .then((response) => response.data);
  }
}
