export const productsApiUrls = {
  products: '/api/v1/products',
  productsBulkUpdate: '/api/v1/products/bulk-update',
  product: '/api/v1/products/{productId}',
  duplicate: '/api/v1/products/{productId}/duplicate',
  bulkDuplicate: '/api/v1/products/bulk-duplicate',
  productsDelete: '/api/v1/products',
  productsBadges: '/api/v1/badges',
  export: '/products/v1/export',
  import: '/products/v1/import',
  importJob: '/job?slug=import_products',
  createDraft: '/api/v1/products/create-draft',
  uploadProductImage: '/api/v1/products/{productId}/images/upload',
  locations: '/api/v1/products/locations',
  updateProduct: '/api/v1/products/{productId}/update',
  deleteProductImage: '/api/v1/products/{productId}/images/{imageId}/delete',
  editImageAlt: '/api/v1/products/{productId}/images/{imageId}/alt',
  addProductCategory: '/api/v1/products/{productId}/categories/add',
  deleteProductCategory: '/api/v1/products/{productId}/categories/delete',
  deleteFiltrationCriteria: '/api/v1/attributes/{attributeId}/extra-attribute-values/{id}',
  addExtraAttributesToProduct: '/api/v1/products/attributes/{attributeId}/extra-attribute-values',
  getAttributes: '/products/v1/attributes',
  options: '/api/v1/products/attributes',
  option: '/api/v1/products/attributes/{attributeId}',
  optionPresets: '/api/v1/products/attributes/{attributeId}/presets',
  optionPreset: '/api/v1/products/attributes/{attributeId}/presets/{presetId}',
  sortProductImage: '/products/v1/{productId}/images/sorting',
  updateProductCustomInputField: '/products/v1/{productId}/input',
  updateProductCustomSelectionField: '/products/v1/{productId}/selection',
  deleteProductCustomInputField: '/products/v1/{productId}/input/delete',
  deleteProductCustomSelectionField: '/products/v1/{productId}/selection/delete',
  uploadProductDescriptionImage: '/products/v1/{productId}/description-images/upload',
  addProductVariants: '/api/v1/products/{productId}/variants',
  addProduct: '/api/v1/products/add',
  productVouchers: '/api/v1/products/{productId}/vouchers',
  productVoucher: '/api/v1/products/{productId}/vouchers/{voucherId}',
  importVouchers: '/api/v1/products/{productId}/vouchers/import',
  deleteStock: '/products/v1/{productId}/stocks/{stockId}',
  similarProducts: '/api/v1/similar-products/{productId}',
  updateSimilarProducts: '/api/v1/similar-products/{productId}/update',
  restoreProducts: '/api/v1/products/restore',
  generateBarcode: '/api/v1/products/generate-barcodes',
  productsQuantityLogs: '/api/v1/products/stock-history',
};
