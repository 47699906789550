import { Route } from 'vue-router';
import UserStoreModule from '../../user/store/module';
import { RoutingRouteEnum } from '../route.enum';
import { groupedProductsPilotStores } from '../pilots/grouped-products.pilot';

export function hasGroupedProducts(to: Route, from: Route, next: Function) {
  const userId = UserStoreModule.data?.store?.id;
  const isInPilot = userId && groupedProductsPilotStores.includes(userId);

  if (isInPilot) {
    next();
  } else {
    next({
      name: RoutingRouteEnum.home,
    });
  }
}
