import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from './factory';
import { AppStoreSettingsInterface } from './settings.interface';
import I18nLocaleEnum from '../../i18n/locale.enum';
import { AxiosError } from 'axios';

@Module({
  dynamic: true,
  name: 'app',
  store: appStoreFactory(),
  namespaced: true,
})
class AppModule extends VuexModule {
  public settings: AppStoreSettingsInterface | null = null;
  public error: AxiosError | null = null;

  @Mutation
  private INITIALIZE(settings: AppStoreSettingsInterface): void {
    this.settings = settings;
  }

  @Mutation
  private TOGGLE_NAVBAR(): void {
    if (this.settings) {
      this.settings.navbarCollapsed = !this.settings.navbarCollapsed;
      console.log(this.settings.navbarCollapsed, 'navbarCollapsed');
    }
  }

  @Mutation
  private CLOSE_NAVBAR(): void {
    if (this.settings) {
      this.settings.navbarCollapsed = true;
    }
  }

  @Action
  public initialize(settings: AppStoreSettingsInterface): void {
    this.INITIALIZE(settings);
  }

  @Action
  public toggleNavbar(): void {
    this.TOGGLE_NAVBAR();
  }

  @Action
  public closeNavbar(): void {
    this.CLOSE_NAVBAR();
  }

  public get locale(): I18nLocaleEnum {
    return this.settings?.locale ?? I18nLocaleEnum.ar;
  }
}

export const AppStoreModule = getModule(AppModule);
