export enum UserPermissionSlugEnum {
  storeCustomization = 'manage.store.front.customization',
  zidKeys = 'manage.store.zid.keys',
  editOrders = 'edit.store.orders',
  settingsStore = 'manage.store.account.settings',
  staffNotifications = 'manage.store.staff.notifications',
  coupons = 'manage.store.coupons',
  addCoupon = 'manage.store.coupons',
  viewCoupon = 'manage.store.coupons',
  editCoupon = 'manage.store.coupons',
  couponsReports = 'manage.store.coupons',
  discountRules = 'manage.store.discount.rules',
  bundleOffers = 'manage.store.bundleoffers',
  bundleOfferCreation = 'manage.store.bundleoffers',
  bundleOfferEdit = 'manage.store.bundleoffers',
  home = 'view.store.home',
  reports = 'view.store.dashboard',
  analyticsV2 = 'view.store.dashboard',
  analyticsReports = 'view.store.dashboard',
  liveAnalytics = 'view.store.dashboard',
  orderDrafts = 'list.store.order.drafts',
  orders = 'list.store.orders,core.mazeed.list.orders',
  ordersExport = 'export.store.orders',
  orderNotifications = 'list.store.orders,core.mazeed.list.orders',
  orderDetails = 'list.store.orders,create.store.orders,edit.store.orders,core.mazeed.list.orders',
  reverseOrder = 'list.store.orders,edit.store.orders',
  ordersInvoices = 'list.store.orders,core.mazeed.list.orders',
  vatSettings = 'manage.store.vat.settings',
  orderCreation = 'create.store.orders,list.store.products',
  orderDraft = 'create.store.orders,list.store.products',
  paymentOptions = 'manage.store.ecosystem.payments',
  products = 'list.store.products',
  productOption = 'list.store.products',
  optionsLibrary = 'list.store.products',
  similarProducts = 'list.store.products',
  createProduct = 'list.store.products',
  createDonation = 'list.store.products',
  editProduct = 'list.store.products',
  bulkEdit = 'list.store.products',
  productsExport = 'export.store.products',
  donation = 'list.store.products',
  donations = 'list.store.products',
  editDonation = 'list.store.products',
  subscription = 'manage.store.subscription',
  subscriptionsCheckout = 'manage.store.subscription',
  subscriptionsCheckoutThank = 'manage.store.subscription',
  subscriptionsManagement = 'manage.store.subscription',
  storeSettings = 'manage.store.settings',
  customers = 'list.store.customers',
  customer = 'list.store.customers',
  customersExport = 'export.store.customers',
  deliveryOptions = 'manage.store.ecosystem.delivery',
  zidShip = 'manage.zidship.settings,manage.zidship.orders,manage.zidship.payments,view.zidship.reports',
  zidshipServiceLevelCustomization = 'manage.zidship.settings',
  zidshipServiceLevelDetails = 'manage.zidship.settings',
  zidShipOrders = 'manage.zidship.orders',
  zidshipShipments = 'manage.zidship.orders',
  zidShipOrderDetails = 'manage.zidship.orders',
  zidShipReverseOrder = 'manage.zidship.orders,manage.zidship.payments',
  zidShipPayment = 'manage.zidship.payments',
  zidShipWallet = 'manage.zidship.payments',
  zidshipWaybillsPackages = 'manage.zidship.payments',
  zidShipMerchantReports = 'view.zidship.reports',
  zidShipSettings = 'manage.zidship.settings',
  zidshipHelpCenter = 'view.store.home',
  merchantReports = 'manage.store.ecosystem.delivery,view.zidship.reports',
  domains = 'manage.store.custom.domain',
  storePages = 'manage.store.front.pages',
  categories = 'list.store.categories',
  category = 'list.store.categories',
  zidAcademy = 'view.zid.academy',
  fulfilment = 'manage.store.ecosystem.orderfulfillment',
  photography = 'manage.store.ecosystem.photographics',
  design = 'manage.store.ecosystem.designs',
  selfStorage = 'manage.store.ecosystem.selfstorage',
  marketing = 'manage.store.ecosystem.marketing',
  packaging = 'manage.store.ecosystem.packaging',
  coworkingSpaces = 'manage.store.ecosystem.workplaces',
  prints = 'manage.store.ecosystem.printings',
  partners = 'view.store.home',
  moderators = 'view.store.home',
  empowerment = 'view.store.home',
  mobileAppLandingPage = 'view.store.home',
  mobileAppManager = 'manage.store.ecosystem.mobileapps',
  mobileAppManagerPushNotifications = 'manage.mobile.app.notifications',
  mobileAppManagerDiscounts = 'manage.mobile.app.discount',
  mobileAppManagerBanner = 'manage.mobile.app.banner',
  smsPricing = 'view.store.home',
  seo = 'view.store.home',
  fosteringNgo = 'view.store.home',
  abandonedCarts = 'manage.store.abandonedcarts',
  abandonedCartsDetails = 'manage.store.abandonedcarts',
  abandonedCartsExport = 'export.store.abandonedcarts',
  zidpay = 'list.zidpay.transactions,list.zidpay.deposits,manage.zidpay.setting',
  zidpayMarketing = 'list.zidpay.transactions,list.zidpay.deposits,manage.zidpay.setting',
  zidpayActivation = 'manage.zidpay.setting',
  zidpayActivationConfirmation = 'manage.zidpay.setting',
  zidpayTransactions = 'list.zidpay.transactions',
  zidpayTransaction = 'list.zidpay.transactions',
  zidpayPaymentLink = 'list.zidpay.transactions',
  zidpayPaymentLinks = 'list.zidpay.transactions',
  zidpayDeposits = 'list.zidpay.deposits',
  zidpayDeposit = 'list.zidpay.deposits',
  zidpayCreateRefund = 'refund.zidpay.transactions',
  zidpaySettings = 'manage.zidpay.setting',
  finance = 'manage.zid.financing',
  financeMarketing = 'manage.zid.financing',
  financeActivation = 'manage.zid.financing',
  financeLanding = 'manage.zid.financing',
  financeSoon = 'manage.zid.financing',
  mazeed = 'core.mazeed.manage,core.mazeed.list.orders,core.mazeed.view.settlements',
  mazeedActivation = 'core.mazeed.manage',
  mazeedActivationProducts = 'core.mazeed.manage',
  mazeedActivationAgreements = 'core.mazeed.manage',
  mazeedActivationBusinessInfo = 'core.mazeed.manage',
  mazeedActivationSuccess = 'core.mazeed.manage',
  mazeedSettings = 'core.mazeed.manage',
  mazeedStoreUpdates = 'core.mazeed.manage',
  mazeedProductsUpdates = 'core.mazeed.manage',
  mazeedReportsSection = 'core.mazeed.manage',
  mazeedOrdersSection = 'core.mazeed.list.orders',
  mazeedSettlementInvoice = 'core.mazeed.view.settlements',
  mazeedSettlements = 'core.mazeed.view.settlements',
  appMarket = 'view.app.market.home',
  appMarketBrowseApps = 'view.app.market.browse',
  appMarketDetails = 'manage.app.market.details',
  appMarketEmbedded = 'manage.app.market.embedded.app',
  appMarketMyApps = 'manage.app.market.settings',
  appMarketAppsCollection = 'view.app.market.home',
  themeStore = 'manage.store.front.customization',
  themeAdded = 'manage.store.front.customization',
  settingsInventoryAddresses = 'manage.store.inventory.addresses',
  settingsInventoryCreation = 'manage.store.inventory.addresses',
  settingsInventoryAddress = 'manage.store.inventory.addresses',
  settingsInventoryAddressesV2 = 'manage.store.inventory.addresses',
  settingsInventoryAddressesV2Priority = 'manage.store.inventory.addresses',
  settingsInventoryAddressesV2Stocks = 'manage.store.inventory.addresses',
  settingsInventoryCreationV2 = 'manage.store.inventory.addresses',
  settingsInventoryAddressV2 = 'manage.store.inventory.addresses',
  settingsInventoryTransferRequests = 'stock.transfer.list',
  settingsInventoryTransferRequestDetails = 'stock.transfer.list',
  settingsInventoryTransferRequestModification = 'stock.transfer.modify',
  settingsInventoryTransferRequestCreation = 'stock.transfer.request',
  settingsInventoryTransferRequestReceive = 'stock.transfer.receive',
  settingsInventoryTransferRequestSend = 'stock.transfer.send',
  settingsInventoryTransferRequestApproval = 'stock.transfer.approval',
  affiliateList = 'manage.store.affiliate',
  loyaltyProgram = 'list.store.customers',
  affiliateCreation = 'manage.store.affiliate',
  affiliateDetails = 'manage.store.affiliate',
  shippingOptions = 'manage.store.ecosystem.delivery',
  shippingRequirments = 'manage.store.ecosystem.delivery',
  shippingActivation = 'manage.store.ecosystem.delivery',
  shippingDetails = 'manage.store.ecosystem.delivery',
  shippingDetailsUpdate = 'manage.store.ecosystem.delivery',
  settingsOrdersProductsSettings = 'manage.store.settings',
  settingsAccount = 'manage.store.account.settings',
  storeDesign = 'manage.store.front.customization',
  ticktes = 'manage.store.front.ticktes',
  zidpos = 'pos.homepage',
  zidposReports = 'pos.analytics',
  zidposPaymentMethods = 'pos.payment.methods',
  zidposCashiers = 'pos.cashiers.page',
  zidposSubscribe = 'view.store.home',
  zidposPreSubscription = 'view.store.home',
  themeMarketDetails = 'view.store.home',
  themeMarketHome = 'view.store.home',
  themeMarketBrowse = 'view.store.home',
  smsCampaignsPricing = 'view.store.home',
  smsCampaigns = 'view.store.home',
  createSmsCampaign = 'view.store.home',
  editSmsCampaign = 'view.store.home',
  viewSmsCampaign = 'view.store.home',
  giftOptions = 'view.store.home',
  langCurrency = 'manage.store.settings',
  abandonedCartsMessages = 'manage.store.settings',
  ticketing = 'manage.store.settings',
  createTicket = 'manage.store.settings',
  ticketDetails = 'manage.store.settings',
  promotionalMessages = 'manage.store.settings',
  checkoutOptions = 'manage.store.settings',
  abandonedCartsCreateMessage = 'manage.store.settings',
  filtrationAttribute = 'list.store.products',
  questionsRatings = 'manage.store.settings',
  ratingsNotifications = 'manage.store.settings',
  ratingsQuestionsMessages = 'manage.store.settings',
  signOutFromAllDevices = 'manage.store.account.settings.signout',
  walletWithdraw = 'wallet.withdraw',
  walletViewHistory = 'wallet.view.history',
  walletViewBalance = 'wallet.view.balance',
  walletTopUp = 'wallet.topup',
  metafields = 'manage.store.subscription',
  addMetafield = 'manage.store.subscription',
  editMetafield = 'manage.store.subscription',
  addExtraPage = 'manage.store.front.pages',
  editExtraPage = 'manage.store.front.pages',
  zidWallet = 'wallet.view.history',
  groupedProduct = 'list.store.products',
  productVariant = 'list.store.products',
  productsRestore = 'list.store.products',
}
