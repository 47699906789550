import { ApiBaseResponseObjectType } from './../../../api/types';
import { OrdersApiOrderNotificationsMessageResponseInterface } from './message/response.interface';
import ApiClient from '../../../api/client';
import ApiHttpMethodEnum from '../../../api/http-method.enum';
import { orderNotificationsApiUrls } from './urls';
import { OrdersApiOrderNotificationsDataResponseInterface } from './data/response.interface';
import {
  ApiModelOrderNotificationStreakImageToShareResponseInterface,
  ApiModelOrderNotificationStreakResponseInterface,
} from '../order-streak/response.interface';

export class OrderNotificationsApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getData(): Promise<OrdersApiOrderNotificationsDataResponseInterface> {
    const response = await this.apiClient.request<OrdersApiOrderNotificationsDataResponseInterface>(
      ApiHttpMethodEnum.get,
      orderNotificationsApiUrls.balance,
    );

    return response.data;
  }

  public async setOrderStatusesToSendSms(
    // eslint-disable-next-line @typescript-eslint/camelcase
    order_statuses: Array<number>,
  ): Promise<OrdersApiOrderNotificationsDataResponseInterface> {
    const url = orderNotificationsApiUrls.statuses;

    const response = await this.apiClient.request<OrdersApiOrderNotificationsDataResponseInterface>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          order_statuses,
        },
      },
    );

    return response.data;
  }

  public async viewOrderNotificationByOrderStatus(
    status: string,
  ): Promise<OrdersApiOrderNotificationsMessageResponseInterface> {
    const url = orderNotificationsApiUrls.message.replace('{status}', status);
    const response = await this.apiClient.request<OrdersApiOrderNotificationsMessageResponseInterface>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response.data;
  }

  public async deleteOrderNotifications(): Promise<ApiBaseResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseResponseObjectType>(
      ApiHttpMethodEnum.delete,
      orderNotificationsApiUrls.orderNotifications,
    );

    return response.data;
  }

  public async setOrderNotification(
    data: Record<string, string>,
  ): Promise<OrdersApiOrderNotificationsMessageResponseInterface> {
    const url = orderNotificationsApiUrls.orderNotifications;

    const response = await this.apiClient.request<OrdersApiOrderNotificationsMessageResponseInterface>(
      ApiHttpMethodEnum.post,
      url,
      {
        data,
      },
    );

    return response.data;
  }

  public async getSenderServices(): Promise<any> {
    const response = await this.apiClient.request<OrdersApiOrderNotificationsMessageResponseInterface>(
      ApiHttpMethodEnum.get,
      orderNotificationsApiUrls.senderIdServices,
    );

    return response.data;
  }

  public async getOrderStreak(): Promise<ApiModelOrderNotificationStreakResponseInterface> {
    const response = await this.apiClient.request<ApiModelOrderNotificationStreakResponseInterface>(
      ApiHttpMethodEnum.get,
      orderNotificationsApiUrls.orderStreak,
    );
    return response.data;
  }

  public async getOrderStreakImageToShare(): Promise<ApiModelOrderNotificationStreakImageToShareResponseInterface> {
    const response = await this.apiClient.request<ApiModelOrderNotificationStreakImageToShareResponseInterface>(
      ApiHttpMethodEnum.get,
      orderNotificationsApiUrls.orderStreakImageToShare,
    );
    return response.data;
  }
}
