import UserStoreModule from '../../user/store/module';
import I18nMessages from '../../i18n/messages';
import I18nLocaleEnum from '../../i18n/locale.enum';
import { AppStoreModule } from './module';
import { AppThemeEnum } from '../theme.enum';

export function appStoreHydrate(): boolean {
  const initialState = window.__INITIAL_STATE__;

  let navbarCollapsed = false;

  if (window.matchMedia('(max-width: 768px)').matches) {
    navbarCollapsed = true;
  }

  if (!initialState) {
    return false;
  }

  if (initialState.hasOwnProperty('user')) {
    UserStoreModule.hydrate(initialState.user);
  }

  let locale: I18nLocaleEnum = I18nLocaleEnum.ar;
  if (initialState.hasOwnProperty('appLocale') && I18nMessages.hasOwnProperty(initialState.appLocale)) {
    locale = initialState.appLocale as I18nLocaleEnum;
  }

  AppStoreModule.initialize({
    locale,
    theme: AppThemeEnum.light,
    navbarCollapsed,
  });

  return true;
}
