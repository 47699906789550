export const customersApiUrls = {
  customers: '/api/v1/customers',
  updateCustomer: '/api/v1/customers/{customerId}',
  deleteCustomer: '/api/v1/customers/{customerId}',
  customerPrimaryData: '/api/v1/customers/{customerId}',
  customerProfile: '/api/v1/customers/{customerId}/profile',
  groupDetails: '/api/v1/groups/{groupId}',
  nameAvailability: '/api/v1/groups/name-availability',
  customersPrimary: '/api/v1/customers-primary',
  customersAddress: '/api/v1/customers/address',
  importCustomers: '/api/v1/customers/import',
  getImportCustomersJobStatus: '/api/v1/customers/import/{jobId}',
  getImportCustomersJobProgress: '/api/v1/customers/import/{jobId}/progress',
  getImportCustomersJobReport: '/api/v1/customers/report/{jobId}',
  exportAll: '/api/v1/customers/export/all',
  ListCustomersGroup: '/api/v1/groups',
  deleteGroup: '/api/v1/groups/{groupId}',
  editGroup: '/api/v1/groups/{groupId}',
  CreateCustomersGroup: '/api/v1/groups',
  customerAddNote: '/api/v1/customers/{customerId}/note',
  activateCustomer: '/api/v1/customers/{customerId}/activate',
  banCustomer: '/api/v1/customers/{customerId}/ban',
  customerEnableCod: '/api/v1/customers/{customerId}/enable-cod',
  customerDisableCod: '/api/v1/customers/{customerId}/disable-cod',
  customerAddGender: '/api/v1/customers/{customerId}/gender',
  customerAddBirthDate: '/api/v1/customers/{customerId}/birth-date',
  customerAddresses: '/api/v1/customers/{customerId}/addresses',
  deleteAddress: '/api/v1/customers/{customerId}/addresses/{addressId}',
  createCustomerAddress: '/api/v1/customers/{customerId}/addresses',
  setDefaultAddress: '/api/v1/customers/{customerId}/addresses/{addressId}/set-default',
  updateCustomerAddress: '/api/v1/customers/{customerId}/addresses/{addressId}',
  enableNewsletter: '/api/v1/customers/{customerId}/enable-newsletter',
  disableNewsletter: '/api/v1/customers/{customerId}/disable-newsletter',
};
