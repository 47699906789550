import { CustomersApiCustomerAddressResponseInterface } from './customer/address-response.interface';
import { OrdersApiDraftCustomerAddressRequestInterface } from './../../orders/api/orders/drafts/customer-address/request.interface';
import { CustomersApiCustomerResponseInterface } from './customer/response.interface';
import { CustomersApiCustomerRequestInterface } from './customer/request.interface';
import ApiClient from '../../api/client';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import { CustomersApiFiltersInterface } from './filters.interface';
import { CustomersApiPrimaryResponseInterface } from './primary/response.interface';
import { customersApiFiltersToSearchParams } from './filters-to-search-params';
import { customersApiUrls } from './urls';
import { urlQueryFormat } from '../../common/helpers/url/query/format';
import { CustomersPaginatedApiResponseInterface } from '../types/customers-paginated.interface';
import { CustomerTypesEditFormRequestInterface } from '../types/customer-edit-form-data.interface';
import { CustomerTypesCustomerModelResponseInterface } from '../types/customer-model-response.interface';
import {
  ApiBaseDataResponseObjectType,
  ApiBaseMessageResponseObjectType,
  ApiBaseResponseObjectType,
  ApiBaseResponseType,
  ApiResponseType,
} from '../../api/types';
import { CustomerTypesAddFormRequestInterface } from '../types/customer-add-form-data.interface';
import { CustomersTypesImportCustomersResponseInterface } from '../types/import-customers-response.interface';
import { CustomersTypesImportStatusResponseInterface } from '../types/import-status-response.interface';
import { CustomersTypesImportProgressResponseInterface } from '../types/import-progress-response.interface';
import { CustomerTypesCustomerProfileResponseInterface } from '../types/customer-view-response.interface';
import { ApiModelCustomerPrimaryInterface } from '../../api/model/customer-primary.interface';
import { CustomersTypesAddressesFiltersInterface } from '../types/address-filters.interface';
import { AddressesPaginatedApiResponseInterface } from '../types/addresses-paginated.interface';
import { CustomersTypesAddressModelInterface } from '../types/address-model.interface';
import { ApiGenderEnum } from '../../api/gender.type';
import { CustomersGroupsPaginatedApiResponseInterface } from '../types/customers-groups-paginated.interface';

import { CustomerGroupsTypesAddFormRequestInterface } from '../types/customers-group-add-form-data.interface';
import { CustomersGroupApiFiltersInterface } from './customers-groups-filters.interface';
import { CustomersGroupTypesCustomerProfileInterface } from '../types/customer-group-profile.interface';

export class CustomersApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getCustomers(
    filters: CustomersApiFiltersInterface,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<CustomersPaginatedApiResponseInterface>>> {
    const params = urlQueryFormat(customersApiFiltersToSearchParams(filters));

    const response = this.apiClient.request<ApiBaseDataResponseObjectType<CustomersPaginatedApiResponseInterface>>(
      ApiHttpMethodEnum.get,
      customersApiUrls.customers,
      {
        params,
      },
    );

    return response;
  }

  public async getCustomersGroups(
    filters: CustomersGroupApiFiltersInterface,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<CustomersGroupsPaginatedApiResponseInterface>>> {
    const params = urlQueryFormat(customersApiFiltersToSearchParams(filters));
    const response = this.apiClient.request<
      ApiBaseDataResponseObjectType<CustomersGroupsPaginatedApiResponseInterface>
    >(ApiHttpMethodEnum.get, customersApiUrls.ListCustomersGroup, { params });
    return response;
  }

  public async getCustomersPrimary(
    filters: CustomersApiFiltersInterface,
  ): Promise<CustomersApiPrimaryResponseInterface> {
    const params = urlQueryFormat(customersApiFiltersToSearchParams(filters));

    const response = await this.apiClient.request<CustomersApiPrimaryResponseInterface>(
      ApiHttpMethodEnum.get,
      customersApiUrls.customersPrimary,
      {
        params,
      },
    );

    return response.data;
  }

  public async createCustomer(
    data: CustomersApiCustomerRequestInterface | CustomerTypesAddFormRequestInterface,
  ): Promise<CustomersApiCustomerResponseInterface> {
    const response = await this.apiClient.request<CustomersApiCustomerResponseInterface>(
      ApiHttpMethodEnum.post,
      customersApiUrls.customers,
      {
        data,
      },
    );

    return response.data;
  }

  public async createAddress(
    data: OrdersApiDraftCustomerAddressRequestInterface,
  ): Promise<CustomersApiCustomerAddressResponseInterface> {
    const response = await this.apiClient.request<CustomersApiCustomerAddressResponseInterface>(
      ApiHttpMethodEnum.post,
      customersApiUrls.customersAddress,
      {
        data,
      },
    );

    return response.data;
  }

  public async updateCustomer(
    customerId: number,
    data: CustomerTypesEditFormRequestInterface,
  ): Promise<ApiBaseDataResponseObjectType<CustomerTypesCustomerModelResponseInterface>> {
    const url = customersApiUrls.updateCustomer.replace('{customerId}', customerId.toString());

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<CustomerTypesCustomerModelResponseInterface>
    >(ApiHttpMethodEnum.post, url, {
      data,
    });

    return response.data;
  }

  public async deleteCustomer(customerId: number): Promise<ApiBaseMessageResponseObjectType> {
    const url = customersApiUrls.deleteCustomer.replace('{customerId}', customerId.toString());

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.delete, url);

    return response.data;
  }

  public async deleteGroup(groupId: string): Promise<ApiBaseMessageResponseObjectType> {
    const url = customersApiUrls.deleteGroup.replace('{groupId}', groupId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.delete, url);

    return response.data;
  }

  public async importCustomers(
    data: FormData,
  ): Promise<ApiBaseDataResponseObjectType<CustomersTypesImportCustomersResponseInterface>> {
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<CustomersTypesImportCustomersResponseInterface>
    >(ApiHttpMethodEnum.post, customersApiUrls.importCustomers, {
      data,
    });

    return response.data;
  }

  public async getImportStatus(
    jobId: string,
  ): Promise<ApiBaseDataResponseObjectType<CustomersTypesImportStatusResponseInterface>> {
    const url = customersApiUrls.getImportCustomersJobStatus.replace('{jobId}', jobId);

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<CustomersTypesImportStatusResponseInterface>
    >(ApiHttpMethodEnum.get, url);

    return response.data;
  }

  public async getImportProgress(
    jobId: string,
  ): Promise<ApiBaseDataResponseObjectType<CustomersTypesImportProgressResponseInterface>> {
    const url = customersApiUrls.getImportCustomersJobProgress.replace('{jobId}', jobId);

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<CustomersTypesImportProgressResponseInterface>
    >(ApiHttpMethodEnum.get, url);

    return response.data;
  }

  public async exportAll(filters: CustomersApiFiltersInterface): Promise<ApiBaseMessageResponseObjectType> {
    const data = customersApiFiltersToSearchParams(filters);
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(
      ApiHttpMethodEnum.post,
      customersApiUrls.exportAll,
      { data },
    );

    return response.data;
  }

  public async createCustomersGroup(
    formData: CustomerGroupsTypesAddFormRequestInterface,
  ): Promise<ApiBaseMessageResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(
      ApiHttpMethodEnum.post,
      customersApiUrls.CreateCustomersGroup,
      {
        data: formData,
      },
    );

    return response.data;
  }

  public async editCustomersGroup(
    formData: CustomerGroupsTypesAddFormRequestInterface,
    groupId: string,
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = customersApiUrls.editGroup.replace('{groupId}', groupId);
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.put, url, {
      data: formData,
    });

    return response.data;
  }

  public async exportSelectedCustomers(customersIds: Array<number>): Promise<ApiBaseMessageResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(
      ApiHttpMethodEnum.post,
      customersApiUrls.exportAll,
      {
        data: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          customers_ids: customersIds,
        },
      },
    );

    return response.data;
  }

  public async bulkDelete(customers: Array<number>): Promise<ApiBaseMessageResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(
      ApiHttpMethodEnum.delete,
      customersApiUrls.customers,
      {
        data: {
          customers,
        },
      },
    );

    return response.data;
  }

  public async getCustomerProfile(
    customerId: number,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<CustomerTypesCustomerProfileResponseInterface>>> {
    const url = customersApiUrls.customerProfile.replace('{customerId}', customerId.toString());

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<CustomerTypesCustomerProfileResponseInterface>
    >(ApiHttpMethodEnum.get, url, { cacheable: true });

    return response;
  }
  public async getGroupDetails(groupId: string, filters: CustomersGroupApiFiltersInterface): Promise<any> {
    const url = customersApiUrls.groupDetails.replace('{groupId}', groupId);
    const params = urlQueryFormat(customersApiFiltersToSearchParams(filters));

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<CustomersGroupTypesCustomerProfileInterface>
    >(ApiHttpMethodEnum.get, url, {
      params,
    });

    return response.data;
  }
  public async checkNameAvailability(name: string): Promise<ApiBaseResponseType> {
    const url = customersApiUrls.nameAvailability;

    const params = urlQueryFormat({ name: name });

    const response = await this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.get, url, { params });
    return response.data;
  }

  public async getCustomerPrimaryData(
    customerId: number,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelCustomerPrimaryInterface>> {
    const url = customersApiUrls.customerPrimaryData.replace('{customerId}', customerId.toString());

    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ApiModelCustomerPrimaryInterface>>(ApiHttpMethodEnum.get, url)
      .then((result) => result.data);
  }

  public async addNote(customerId: number, note: string): Promise<ApiBaseMessageResponseObjectType> {
    const url = customersApiUrls.customerAddNote.replace('{customerId}', customerId.toString());

    return this.apiClient
      .request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
        data: {
          note,
        },
      })
      .then((result) => result.data);
  }

  public async activateCustomer(customerId: number): Promise<ApiBaseResponseObjectType> {
    const url = customersApiUrls.activateCustomer.replace('{customerId}', customerId.toString());

    return this.apiClient.request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.post, url).then((result) => result.data);
  }

  public async banCustomer(customerId: number): Promise<ApiBaseResponseObjectType> {
    const url = customersApiUrls.banCustomer.replace('{customerId}', customerId.toString());

    return this.apiClient.request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.post, url).then((result) => result.data);
  }

  public async enableCod(customerId: number): Promise<ApiBaseResponseObjectType> {
    const url = customersApiUrls.customerEnableCod.replace('{customerId}', customerId.toString());

    return this.apiClient.request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.post, url).then((result) => result.data);
  }

  public async disableCod(customerId: number): Promise<ApiBaseResponseObjectType> {
    const url = customersApiUrls.customerDisableCod.replace('{customerId}', customerId.toString());

    return this.apiClient.request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.post, url).then((result) => result.data);
  }

  public async addGender(customerId: number, gender: ApiGenderEnum): Promise<ApiBaseMessageResponseObjectType> {
    const url = customersApiUrls.customerAddGender.replace('{customerId}', customerId.toString());

    return this.apiClient
      .request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
        data: {
          gender,
        },
      })
      .then((result) => result.data);
  }

  public async addBirthDate(customerId: number, date: string): Promise<ApiBaseMessageResponseObjectType> {
    const url = customersApiUrls.customerAddBirthDate.replace('{customerId}', customerId.toString());

    return this.apiClient
      .request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
        data: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          birth_date: date,
        },
      })
      .then((result) => result.data);
  }

  public async enableNewsletter(customerId: number): Promise<ApiBaseMessageResponseObjectType> {
    const url = customersApiUrls.enableNewsletter.replace('{customerId}', customerId.toString());

    return this.apiClient
      .request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url)
      .then((result) => result.data);
  }

  public async disableNewsletter(customerId: number): Promise<ApiBaseMessageResponseObjectType> {
    const url = customersApiUrls.disableNewsletter.replace('{customerId}', customerId.toString());

    return this.apiClient
      .request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url)
      .then((result) => result.data);
  }

  public async getCustomerAddresses(
    customerId: number,
    filters: CustomersTypesAddressesFiltersInterface,
  ): Promise<ApiBaseDataResponseObjectType<AddressesPaginatedApiResponseInterface>> {
    const params = urlQueryFormat(customersApiFiltersToSearchParams(filters));
    const url = customersApiUrls.customerAddresses.replace('{customerId}', customerId.toString());

    return this.apiClient
      .request<ApiBaseDataResponseObjectType<AddressesPaginatedApiResponseInterface>>(ApiHttpMethodEnum.get, url, {
        params,
      })
      .then((result) => result.data);
  }

  public async deleteAddress(customerId: number, addressId: number): Promise<ApiBaseMessageResponseObjectType> {
    const url = customersApiUrls.deleteAddress
      .replace('{customerId}', customerId.toString())
      .replace('{addressId}', addressId.toString());

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.delete, url);

    return response.data;
  }

  public async createCustomerAddress(
    data: OrdersApiDraftCustomerAddressRequestInterface,
  ): Promise<ApiBaseDataResponseObjectType<CustomersTypesAddressModelInterface>> {
    /* eslint-disable @typescript-eslint/camelcase */
    const { customer_id, ...restData } = data;

    const url = customersApiUrls.createCustomerAddress.replace(
      '{customerId}',
      customer_id ? customer_id?.toString() : '',
    );
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<CustomersTypesAddressModelInterface>>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: restData,
      },
    );

    return response.data;
  }

  public async setDefaultAddress(customerId: number, addressId: number): Promise<ApiBaseMessageResponseObjectType> {
    const url = customersApiUrls.setDefaultAddress
      .replace('{customerId}', customerId.toString())
      .replace('{addressId}', addressId.toString());

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url);

    return response.data;
  }

  public async updateCustomerAddress(
    addressId: number,
    data: OrdersApiDraftCustomerAddressRequestInterface,
  ): Promise<ApiBaseDataResponseObjectType<CustomersTypesAddressModelInterface>> {
    /* eslint-disable @typescript-eslint/camelcase */
    const { customer_id, ...restData } = data;

    const url = customersApiUrls.updateCustomerAddress
      .replace('{customerId}', customer_id ? customer_id?.toString() : '')
      .replace('{addressId}', addressId.toString());

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<CustomersTypesAddressModelInterface>>(
      ApiHttpMethodEnum.put,
      url,
      {
        data: restData,
      },
    );

    return response.data;
  }
}
