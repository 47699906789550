import ApiClient from '../../../api/client';
import ApiHttpMethodEnum from '../../../api/http-method.enum';
import { ApiBaseResponseType, ApiResponseType } from '../../../api/types';
import { subscriptionManagementApiUrls } from './urls';
import { SubscriptionApiPurchaseHistoryResponseInterface } from './purchase-history/response';
import { urlQueryFormat } from '../../../common/helpers/url/query/format';
import { PurchaseHistoryApiFiltersInterface } from './purchase-history/filters.interface';
import { purchaseHistoryApiFiltersToSearchParams } from './purchase-history/filters-to-search-params';

export class SubscriptionManagementApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getPurchaseHistory(
    filters: PurchaseHistoryApiFiltersInterface,
  ): Promise<ApiResponseType<SubscriptionApiPurchaseHistoryResponseInterface>> {
    const params = urlQueryFormat(purchaseHistoryApiFiltersToSearchParams(filters));
    const response = await this.apiClient.request<SubscriptionApiPurchaseHistoryResponseInterface>(
      ApiHttpMethodEnum.get,
      subscriptionManagementApiUrls.getPurchaseHistory,
      { params },
    );

    return response;
  }

  public async getPaymentCards(): Promise<any> {
    const response = await this.apiClient.request<any>(
      ApiHttpMethodEnum.get,
      subscriptionManagementApiUrls.getPaymentCards,
    );

    return response;
  }

  public async savePaymentCard(cardToken: string): Promise<ApiBaseResponseType> {
    const params = urlQueryFormat({
      cardToken,
    });

    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.post, subscriptionManagementApiUrls.savePaymentCard, {
        params,
      })
      .then((response) => response.data);
  }

  public async deletePaymentCard(cardId: string): Promise<ApiBaseResponseType> {
    const params = urlQueryFormat({
      cardId,
    });

    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.delete, subscriptionManagementApiUrls.deletePaymentCard, {
        params,
      })
      .then((response) => response.data);
  }

  public async setDefaultPaymentCard(cardId: string): Promise<ApiBaseResponseType> {
    const params = urlQueryFormat({
      cardId,
    });

    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.post, subscriptionManagementApiUrls.setDefaultPaymentCard, {
        params,
      })
      .then((response) => response.data);
  }
}
