import Axios from 'axios';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ApiModelInventoryAddressInterface } from '../../../api/model/inventory-address.interface';
import { ApiBaseDataResponseObjectType } from '../../../api/types';
import appStoreFactory from '../../../app/store/factory';
import AppStoreReadyStateEnum from '../../../app/store/ready-state.enum';
import { ZidShipApiServiceFactory } from '../../api/service-factory';
import { ZidShipTypesModelServiceActivationDataInterface } from '../../types/model/service-activation-data.interface';
import { ZidShipTypesModelShipmentsSummaryInterface } from '../../types/model/shipments-summary.interface';
import { ZidShipTypesSelectedServiceLevels } from '../../types/selected-service-levels';
import UserStoreModule from '../../../user/store/module';
import Catch from '../../../common/decorators/catch-error';
import { OrdersApiServiceFactory } from '../../../orders/api/service-factory';
import { OrderFirstMileHasPickupFeatureInterface } from '../../../orders/api/orders/has-first-mile-pickup-feature-response.interface';
import { revalidate } from '../../../common/helpers/revalidate/revalidate';

const zidShipService = ZidShipApiServiceFactory();
const ordersService = OrdersApiServiceFactory();

@Module({
  dynamic: true,
  name: 'service-data',
  store: appStoreFactory(),
  namespaced: true,
})
class ServiceDataModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: ZidShipTypesModelServiceActivationDataInterface | null = null;
  public identityDocument: File[] | string | null = null;
  public selectedServiceLevels: ZidShipTypesSelectedServiceLevels | null = null;
  public inventoryAddress: ApiModelInventoryAddressInterface | null = null;
  public crNumberNotAvailable = !this.data?.commercialRegistrationInfo.cr_number;
  public dashIframeUrl: string | null = null;
  public shipmentsSummary: ZidShipTypesModelShipmentsSummaryInterface | null = null;
  public isZidShipActive = false;
  public hasPickupFeature = false;
  public isIBANVerified = true;
  public isPhoneVerified = true;
  public isKYCComplete = false;
  public error: Error | null = null;

  @Mutation
  private FETCH(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS(data: ZidShipTypesModelServiceActivationDataInterface): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = JSON.parse(JSON.stringify(data));
    this.crNumberNotAvailable = !this.data?.commercialRegistrationInfo.cr_number;
  }

  @Mutation
  private FETCH_SUMMARY_SUCCESS(): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  public KYC_COMPLETE(isKYCComplete: boolean): void {
    this.isKYCComplete = isKYCComplete;
  }

  @Mutation
  private FETCH_DONE(): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  public FETCH_ERROR(error: Error | null): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Mutation
  private RESET_ERROR(): void {
    this.error = null;
  }

  @Action
  private selectSavedAddress(): void {
    const savedAddress =
      this.data?.inventoryAddresses.find(
        (address) =>
          address.city.ar_name === this.data?.consignorContactInfo.inventory_address?.city &&
          this.data?.consignorContactInfo.inventory_address?.line_1.endsWith(address.street),
      ) ?? null;
    this.SET_INVENTORY_ADDRESS(savedAddress);
  }

  @Action
  @Catch({ onError: (error, ctx) => ctx.FETCH_ERROR(error) })
  public async fetch(): Promise<void> {
    this.FETCH();
    const response = await zidShipService.getServiceActivationFormData();

    this.FETCH_SUCCESS(response.data.data);
    this.selectSavedAddress();

    revalidate(response, (fresh: typeof response.data) => {
      this.FETCH_SUCCESS(fresh.data);
      this.selectSavedAddress();
    });
  }

  @Action
  @Catch()
  public async submit(): Promise<ApiBaseDataResponseObjectType> {
    this.RESET_ERROR();
    const service_info = {
      inventory_address_id: this.inventoryAddress?.id,
      consignor_contact_info: this.data?.consignorContactInfo,
      store_name: this.data?.consignorContactInfo.store_name ?? UserStoreModule.data?.store.title,
      civil_id: this.data?.civilId,
    };

    let imageData: FormData | null = null;
    if (this.identityDocument && this.data?.commercialRegistrationInfo.cr_number == null) {
      imageData = new FormData();
      imageData.append('file', this.identityDocument[0]);
      Axios.post('/account/store/identity-document', imageData);
    }

    const request: any = {
      service_info,
      bank_info: this.data?.bankInfo,
      cr_number: this.data?.commercialRegistrationInfo.cr_number,
      service_activate: true,
    };
    const response = await zidShipService.zidShipServiceActivate(request);

    return response;
  }

  @Action
  public setFullName(fullname: string): void {
    this.SET_FULL_NAME(fullname);
  }

  @Action
  public setStoreName(fullname: string): void {
    this.SET_STORE_NAME(fullname);
  }

  @Action
  public setEmail(email: string): void {
    this.SET_EMAIL(email);
  }

  @Action
  public setPhoneNumber(phoneNumber: string): void {
    this.SET_PHONE_NUMBER(phoneNumber);
  }

  @Action
  public setCrNumber(crNumber: string | null): void {
    this.SET_CR_NUMBER(crNumber);
  }

  @Action
  public setCivilId(civilId: string): void {
    this.SET_CIVIL_ID(civilId);
  }

  @Action
  public setBankName(bankName: string): void {
    this.SET_BANK_NAME(bankName);
  }

  @Action
  public setIban(iban: string): void {
    this.SET_IBAN(iban);
  }

  @Action
  public setAccountHolderName(accountHolderName: string): void {
    this.SET_ACCOUNT_HOLDER_NAME(accountHolderName);
  }

  @Action
  public setIdentityDocument(image: File[] | null): void {
    this.SET_IDENTITY_DOCUMENT(image);
  }

  @Action
  public setCrNumberNotAvailable(isChecked: boolean): void {
    this.SET_CR_NUMBER_NOT_AVAILABLE(isChecked);
  }

  @Action
  public setInventoryAddress(address: ApiModelInventoryAddressInterface | null): void {
    this.SET_INVENTORY_ADDRESS(address);
  }

  @Action
  public setServiceLevels(serviceLevels: ZidShipTypesSelectedServiceLevels): void {
    this.SET_SERVICE_LEVELS(serviceLevels);
  }

  @Action
  @Catch({ errorHandler: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async getMerchantReports(): Promise<void> {
    this.FETCH();
    const response: any = await zidShipService.getMerchantReports();
    this.GET_MERCHANTS_REPORTS(response.data?.payload?.url);
    this.FETCH_DONE();
  }

  @Action
  @Catch({ errorHandler: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async getShipmentsSummary(): Promise<void> {
    const response: any = await zidShipService.getShipmentsSummary();
    this.GET_SHIPMENTS_SUMMARY(response.data?.payload?.shipments_summary);
    this.FETCH_SUMMARY_SUCCESS();
  }

  @Action
  @Catch({ errorHandler: (error, ctx) => ctx.FETCH_ERROR(error) })
  public async handleActivationState(): Promise<void> {
    this.FETCH();
    // We made a workaround here
    // If ZidShip balance is not null then we know that the merchant have activated the service.
    const response = await zidShipService.getWalletBallance();
    if (response.data.data !== null) this.HANDLE_ACTIVATION_STATE(true);
    this.FETCH_DONE();

    revalidate(response, (fresh: typeof response.data) => {
      if (fresh.data !== null) this.HANDLE_ACTIVATION_STATE(true);
      this.FETCH_DONE();
    });
  }

  @Action
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async getZidShipStatus(): Promise<ApiBaseDataResponseObjectType> {
    const response = await zidShipService.getZidShipStatus();
    this.HANDLE_ACTIVATION_STATE(response.data as boolean);
    return response;
  }

  @Action
  @Catch({ onError: (error, ctx) => ctx.FETCH_ERROR(error) })
  public async checkIfHasPickupFeature(): Promise<
    ApiBaseDataResponseObjectType<OrderFirstMileHasPickupFeatureInterface> | undefined
  > {
    const response = await ordersService.checkIfHasPickupFeature();
    this.HANDLE_PICKUP_STATE(response.data.has_pickup_feature);
    return response;
  }

  @Action({ rawError: true })
  public setIsIBANVerified(isVerified: boolean): void {
    this.SET_IS_IBAN_VERIFIED(isVerified);
  }

  @Action
  public setIsPhoneVerified(isVerified: boolean): void {
    this.SET_IS_PHONE_VERIFIED(isVerified);
  }

  @Action
  public setIsZidshipActivated(value: boolean): void {
    this.HANDLE_ACTIVATION_STATE(value);
  }

  @Mutation
  private HANDLE_ACTIVATION_STATE(isActive: boolean): void {
    this.isZidShipActive = isActive;
  }

  @Mutation
  private HANDLE_PICKUP_STATE(status: boolean): void {
    this.hasPickupFeature = status;
  }

  @Mutation
  private GET_SHIPMENTS_SUMMARY(summary: ZidShipTypesModelShipmentsSummaryInterface): void {
    this.shipmentsSummary = summary;
  }

  @Mutation
  private GET_MERCHANTS_REPORTS(url: string): void {
    this.dashIframeUrl = url;
  }

  @Mutation
  private SET_FULL_NAME(fullname: string): void {
    this.data!.consignorContactInfo.full_name = fullname;
  }

  @Mutation
  private SET_STORE_NAME(storename: string): void {
    this.data!.consignorContactInfo.store_name = storename;
  }

  @Mutation
  private SET_EMAIL(email: string): void {
    this.data!.consignorContactInfo.email = email;
  }

  @Mutation
  private SET_PHONE_NUMBER(phoneNumber: string): void {
    this.data!.consignorContactInfo.phone_number = phoneNumber;
  }

  @Mutation
  private SET_CR_NUMBER(crNumber: string | null): void {
    this.data!.commercialRegistrationInfo.cr_number = crNumber;
  }

  @Mutation
  private SET_CIVIL_ID(civilId: string): void {
    this.data!.civilId = civilId;
  }

  @Mutation
  private SET_BANK_NAME(bankName: string): void {
    this.data!.bankInfo.bank_name = bankName;
  }

  @Mutation
  private SET_IBAN(iban: string): void {
    this.data!.bankInfo.iban = iban;
  }

  @Mutation
  private SET_ACCOUNT_HOLDER_NAME(accountHolderName: string): void {
    this.data!.bankInfo.account_holder_name = accountHolderName;
  }

  @Mutation
  private SET_IDENTITY_DOCUMENT(image: File[] | null): void {
    this.identityDocument = image;
  }

  @Mutation
  private SET_CR_NUMBER_NOT_AVAILABLE(isChecked: boolean): void {
    this.crNumberNotAvailable = isChecked;
  }

  @Mutation
  private SET_INVENTORY_ADDRESS(address: ApiModelInventoryAddressInterface | null): void {
    this.inventoryAddress = address;
  }

  @Mutation
  private SET_SERVICE_LEVELS(serviceLevels: ZidShipTypesSelectedServiceLevels): void {
    this.selectedServiceLevels = serviceLevels;
  }

  @Mutation
  private SET_IS_IBAN_VERIFIED(isVerified: boolean): void {
    this.isIBANVerified = isVerified;
  }

  @Mutation
  private SET_IS_PHONE_VERIFIED(isVerified: boolean): void {
    this.isPhoneVerified = isVerified;
  }
}

export const ServiceDataStoreModule = getModule(ServiceDataModule);
