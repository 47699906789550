import { ProductsApiFiltersInterface } from './filters.interface';
import { ApiSearchParamsType } from '../../api/types';
import { ProductsApiSearchParamsInterface } from './search-params.interface';
import { objectFilter } from '../../common/helpers/object/filter';

export function productsApiFiltersToSearchParams(filters: ProductsApiFiltersInterface): ApiSearchParamsType {
  // Some of the filters search params use camel case that's not allowed
  // inside the application. Disable the rule in order to prepare the search params object
  /* eslint-disable @typescript-eslint/camelcase */
  const params: ProductsApiSearchParamsInterface = {
    page: filters.page?.toString(),
    page_size: filters.limit?.toString(),
    product_class: filters.productType,
    is_published: filters.isPublished ? String(filters.isPublished) : undefined,
    currency: filters.currency,
    category_id: filters.categoryId?.toString(),
    name: filters.name,
    quantity: filters.quantity === null ? 'null' : filters.quantity?.toString(),
    stock: filters.stock ? String(filters.stock) : undefined,
    ordering: filters.orderBy,
    by: filters.sortBy,
    search: filters.search,
    in_stock: filters.inStock,
    sale_price__isnull: filters.salePriceIsnull,
    search_price_type: filters.searchPriceType,
    search_price_value: filters.searchPriceValue,
    search_quantity_type: filters.searchQuantityType,
    search_quantity_value: filters.searchQuantityValue,
    locations: filters.locations?.toString(),
    top_products: filters.topProducts,
    extended: filters.extended,
    id__in: filters?.idIn,
    fields: filters.fields,
    structure: filters.structure,
    is_infinite: filters.isInfinite,
    deleted: filters.deleted,
  };
  /* eslint-enable @typescript-eslint/camelcase */

  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
