export const groupedProductsPilotStores = [
  1, //Osama's Store in Testing
  3, // Osama's Store in Production
  177837, // Aya Salah's Store in Production
  24109, //Aya Salah's Store in Testing
  37213, // manalali.zid.store
  166193, // ldu1fk.zidthemestore.com
  233293, // njmzjt.zid.store
  41960, // barllina.com
  21894, // basma-telecom.com
  482994, // 3beanksa.com
  158842, // roxashop.com
  439637, // qa-cosmetics.com
  36902, // alsalmanoud.com
  323765, // shop.hjeen.com
  203589, // comfysa.com
  54191, // greenwichksa.com
  37295, // nokttain.com
  18181, // reefi.me
  360515, // samapharma.com
  26842, // khawlah.zidthemestore.com
  50327, // store.entaj.com
  257183, // r05t4w.zid.store
  18593, // aamaad.store
  256571, // mbkijr.zid.store
  550415, // avon-cake.com
  470208, // 12cups.com.sa
  110009, // dkhoonemirates.com
  359744, // zidpos.com
  609603, // blueuniform-ksa.com
  8013, // enjaznotbook.com
  1589, // aromatic.sa
  256794, // leen-co.sa
];
